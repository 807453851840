import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Input from 'rsuite/Input';
import Modal from 'rsuite/Modal';

import { notify, OpenModal } from '../../../containers/styled/alerts';
import { PrimaryButton } from '../../../containers/styled/buttons';
import { TextField } from '../../../containers/styled/inputs';
import { WarningSpan } from '../../../containers/styled/layout';
import { TextAreaField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import {
  createFacilityCategory,
  getFacilityCategories,
  updateFacilityCategory
} from '../../../controllers/facilities/FacilityCategoryController';
import {
  validateLength,
  validateNameUniqueness
} from '../../../controllers/validators';

const messages = defineMessages({
  placeholder: {
    id: 'facility.category.form.placeholder',
    defaultMessage: 'Enter Name of Category'
  },
  detailsPlaceholder: {
    id: 'facility.category.form.details.placeholder',
    defaultMessage: 'Enter Details'
  },

  // CREATE
  createTitle: {
    id: 'facility.category.form.create.title',
    defaultMessage: 'Create Facility Category'
  },
  createHeader: {
    id: 'facility.category.form.create.header',
    defaultMessage: 'Created'
  },
  createSuccess: {
    id: 'facility.category.form.create.success',
    defaultMessage: 'Facility Category Created Successfully'
  },

  // UPDATE
  updateTitle: {
    id: 'facility.category.form.update.title',
    defaultMessage: 'Update Facility Category'
  },
  updateHeader: {
    id: 'facility.category.form.update.header',
    defaultMessage: 'Updated'
  },
  updateSuccess: {
    id: 'facility.category.form.update.success',
    defaultMessage: 'Facility Category Updated Successfully'
  },

  failed: {
    id: 'facility.category.form.failed',
    defaultMessage: 'Something went wrong'
  },
  saveFailed: {
    id: 'facility.category.form.save.failed',
    defaultMessage: 'Could not save Facility Category'
  },

  // VALIDATION ERRORS
  requiredError: {
    id: 'facility.category.form.required.error',
    defaultMessage: 'This field is required'
  },
  nameExistsError: {
    id: 'facility.category.form.name.error.exists',
    defaultMessage: 'Facility Category already exists'
  },
  nameMaxLengthError: {
    id: 'facility.category.form.name.error.length',
    defaultMessage: 'Name should not exceed {limit} characters'
  }
});

const MAX_LENGTH = 40;

/**
 * Facility Category Form screen
 *
 * Facility category form to create / update categories.
 *
 * @component
 * @param {object} item             prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose     handles modal close event
 * @returns category form modal.
 *
 */
const FacilityCategoryForm = ({ item, handleClose }) => {
  const { formatMessage } = useIntl();

  const db = useDatabase();

  const [list, setList] = React.useState([]);
  const [data, setData] = React.useState({ name: '', details: '' });
  const [nameErrors, setNameErrors] = React.useState([]);

  const validateName = () => {
    setNameErrors([]);
    const errors = [];

    try {
      validateLength(data.name, MAX_LENGTH);
    } catch (err) {
      errors.push(
        err.message === 'blank'
          ? formatMessage(messages.requiredError)
          : formatMessage(messages.nameMaxLengthError, { limit: MAX_LENGTH })
      );
    }

    try {
      validateNameUniqueness(data.name, data.id, list);
    } catch (err) {
      errors.push(formatMessage(messages.nameExistsError));
    }

    setNameErrors([errors]);
    return errors.length === 0;
  };

  const submit = (event) => {
    event.stopPropagation();

    if (!validateName()) return;

    let action;
    let header;
    let message;

    if (!data.id) {
      action = createFacilityCategory;
      header = formatMessage(messages.createHeader);
      message = formatMessage(messages.createSuccess);
    } else {
      action = updateFacilityCategory;
      header = formatMessage(messages.updateHeader);
      message = formatMessage(messages.updateSuccess);
    }

    action(db, data).then((done) => {
      notify(
        done ? message : formatMessage(messages.saveFailed),
        header,
        done ? 'success' : 'error'
      );
      handleClose();
    });
  };

  React.useEffect(() => {
    const entry = item || {};
    setData({
      id: entry.id,
      name: entry.name || '',
      details: entry.details || ''
    });
  }, [item]);

  React.useEffect(() => {
    getFacilityCategories(db).then(setList);
  }, [db]);

  return (
    <OpenModal
      onClose={handleClose}
      size='md'
      title={formatMessage(
        !data.id ? messages.createTitle : messages.updateTitle
      )}
    >
      <Modal.Body>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <Label>
                <FormattedMessage
                  id='facility.category.form.label.name'
                  defaultMessage='Category Name'
                />
                <WarningSpan>*</WarningSpan>
              </Label>

              <TextField
                value={data.name}
                onChange={(value) => {
                  setNameErrors([]);
                  setData({ ...data, name: value });
                }}
                placeholder={formatMessage(messages.placeholder)}
                maxLength={MAX_LENGTH}
              />
              {nameErrors.map((msg) => (
                <WarningSpan key={msg}>{msg}</WarningSpan>
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='facility.category.form.label.details'
                  defaultMessage='Details'
                />
              </Label>
              <TextAreaField>
                <Input
                  as='textarea'
                  rows={3}
                  value={data.details}
                  onChange={(value) => setData({ ...data, details: value })}
                  placeholder={formatMessage(messages.detailsPlaceholder)}
                />
              </TextAreaField>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>

      <Modal.Footer>
        <FlexboxGrid justify='end'>
          <FlexboxGrid.Item colspan={12}>
            <PrimaryButton onClick={submit} style={{ width: '100%' }}>
              <FormattedMessage
                id='facility.category.form.submit.button'
                defaultMessage='Submit'
              />
            </PrimaryButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </OpenModal>
  );
};

export default FacilityCategoryForm;
