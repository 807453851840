/**
 * Returns phone number along with the country prefix of the person
 *
 * @param {object} person  instance of type person
 * @param {number} index   1 or 2
 * @returns string
 */
export const getPhoneNumber = (person, index) => {
  const number = person[`phone${index}Number`];
  if (!number) return null;

  const country = person[`phone${index}CountryPrefix`] || '';
  return `${country} ${number}`;
};

/**
 * Returns person name (first middle last)
 *
 * @param {object} person  instance of type person
 * @returns string
 */
export const getPersonName = (person) => `${person.firstName} ${person.middleName || ''} ${person.lastName}`;

/**
 * Returns person full name (title first middle last)
 *
 * @param {object} person  instance of type person
 * @returns string
 */
export const getFullName = (person) => `${person.title || ''} ${getPersonName(person)}`.trim();
