import React from 'react';

import BarChart from '../generics/barChart';
import DoughnutChart from '../generics/doughnutChart';

const donorGenderData = {
  labels: ['Male', 'Female'],
  datasets: [
    {
      label: 'Proportion of Donors by Gender',
      data: [7, 3],
      backgroundColor: ['#B02C17', 'rgba(255, 99, 132, 0.2)'],
      borderColor: ['#B02C17', 'rgba(255, 99, 132, 1)'],
      borderWidth: 1
    }
  ]
};

const donorGenderOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: '100',
  plugins: {
    title: {
      display: true,
      text: 'Proportion of Donor by Gender',
      fontSize: 15
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: { usePointStyle: true }
    }
  }
};

const bloodDonationByMonthData = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  datasets: [
    {
      data: [45, 25, 18, 34, 28, 38, 12, 18, 21, 4, 0, 0],
      borderColor: '#B02C17',
      backgroundColor: '#B02C17',
      fill: true
    }
  ]
};

const bloodDonationChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Number of Blood Donations by Month',
      fontSize: 15
    },
    legend: {
      display: false,
      position: 'top'
    }
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  }
};

const ChartOneSectionView = () => {
  return (
    <div className='flexed-chart-one-section'>
      <div className='chart-one-doughnut-container'>
        <div style={{ height: '100%', width: '90%', margin: 'auto' }}>
          <DoughnutChart data={donorGenderData} options={donorGenderOptions} />
        </div>
      </div>

      <div className='chart-one-bar-container'>
        <div style={{ height: '100%', width: '90%', margin: 'auto' }}>
          <BarChart
            options={bloodDonationChartOptions}
            data={bloodDonationByMonthData}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartOneSectionView;
