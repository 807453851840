import { Navbar, Sidebar } from 'rsuite';
import styled from 'styled-components';

export const Wrapper = styled(Navbar)`
  background-color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.font.sizes.s};
  color: ${(props) => props.theme.colors.font_neutral};

  img {
    margin: 0.5rem;
  }

  i {
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.font.sizes.xm};
    margin: 0.5rem;
  }

  a,
  a:visited,
  a:hover {
    color: ${(props) => props.theme.colors.font};
    background: ${(props) => props.theme.colors.white};
  }

  .rs-navbar-item {
    padding: 32px 16px;
    color: ${(props) => props.theme.colors.white.font_neutral};
  }

  .rs-navbar-item:hover {
    background-color: transparent;
  }
`;

export const StyledSpan = styled.span`
  color: ${(props) => props.theme.colors.font};
  font-size: ${(props) => props.theme.font.sizes.s};
`;

export const SidebarWrapper = styled(Sidebar)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .rs-sidenav-default {
    background-color: transparent;
    color: ${props => props.theme.colors.white};
  }

  .rs-dropdown-toggle {
    background-color: ${props => props.theme.colors.primary};
  }

  .rs-sidenav-collapse-in .rs-dropdown-item {
    padding-left: 46px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .rs-sidenav-nav > .rs-sidenav-item,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle {
    padding-left: 1rem;
    border-radius: 6px;
  }

  .rs-sidenav-nav {
    padding-left: 10px;
  }

  .rs-nav-vertical > .rs-dropdown {
    width: 240px;
  }

  .rs-sidenav-nav > .rs-dropdown.rs-dropdown-expand {
    background-color: ${props => props.theme.colors.primary_dark};
    border-radius: 6px;
    margin-bottom: 5px !important;
  }

  .rs-btn,
  .rs-sidenav-nav > .rs-sidenav-item {
    background-color: transparent !important;
    color: ${props => props.theme.colors.white} !important;
  }

  ul.rs-dropdown-menu {
    background-color: transparent !important;
    padding-left: 6px !important;
  }

  .rs-sidenav-default .rs-dropdown-item.rs-dropdown-item-active {
    color: ${props => props.theme.colors.primary} !important;
  }

  .rs-sidenav-default .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-default .rs-sidenav-item:hover,
  .rs-sidenav-default .rs-sidenav-toggle-button:hover,
  .rs-sidenav-nav > .rs-dropdown.rs-dropdown-expand > .rs-dropdown-toggle {
    background-color: ${props => props.theme.colors.primary_dark};
    color: ${props => props.theme.colors.white};
  }

  .logo {
    margin: 42px auto;
  }

  i {
    margin-right: 1rem;
  }

  .app-version {
    margin: 1rem;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.font.sizes.s};
  }
`;

export const FormattedText = styled.span`
  font-weight: ${props => props.theme.font.weights.standard};
  font-size: ${props => props.theme.font.sizes.s};
`;
