import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for questionnaire answer data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listQuestionnaireAnswers(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        date
        response

        questionnaire { id icode name code version }
        donor { id donorCode fullName personName gender bloodType }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for questionnaire answer data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertQuestionnaireAnswer(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'date')}
          ${getMutationStringValue(doc, 'response')}

          ${getMutationNestedValue(doc, 'questionnaire')}
          ${getMutationNestedValue(doc, 'donor')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize questionnaire answer data with GraphQL endpoint
 */
export const syncQuestionnaireAnswer = (...args) => {
  return buildReplication(
    ...args,
    'questionnaire',
    { pullBuilder, pullQuery: 'listQuestionnaireAnswers' },
    { pushBuilder }
  );
};
