import React from 'react';
import { HashRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import Container from 'rsuite/Container';

import { useAuth } from '../../context/auth';
import { permissions } from '../../utils/auth';
import SignIn from '../authentication/SignIn';
import Dashboard from '../dashboard';
import FacilityCategory from '../facilities/FacilityCategory';
import FacilityType from '../facilities/FacilityType';
import Home from '../Home';
import AdverseEvent from '../reports/adverse-event';
import Appointments from '../reports/appointment';
import BloodDonations from '../reports/blood-donation';
import BloodTests from '../reports/blood-test';
import CrossMatching from '../reports/cross-matching';
import Deliveries from '../reports/deliveries';
import Donors from '../reports/donor';
import Patients from '../reports/patient';
import Processing from '../reports/processing';
import Requests from '../reports/request';
import UserRole from '../users/UserRole';
import LeftSideBar from './LeftSideBar';
import { routes } from './routes';
import UserInfo from './UserInfo';

/**
 * Component that distribute the app layout:
 * - the left side navigation bar
 * - navbar top with user info
 * - and the component linked to the route.
 *
 *     +------+-------------------------+
 *     | Logo |                  User   |
 *     |      +-------------------------+
 *     |      |                         |
 *     |  N   |                         |
 *     |  a   |                         |
 *     |  v   |       Component         |
 *     |  b   |                         |
 *     |  a   |                         |
 *     |  r   |                         |
 *     |      |                         |
 *     +------+-------------------------+
 *
 * @component
 */
const AppLayout = () => {
  const { auth } = useAuth();

  if (!auth || !auth.user) {
    return <Navigate replace to={routes.signin} />;
  }

  return (
    <Container>
      <LeftSideBar />

      <Container>
        <UserInfo />

        <Outlet />
      </Container>
    </Container>
  );
};

/**
 * Component that checks user view permission to display the nested children
 *
 * @component
 */
const ViewPermission = ({ model, children }) => {
  const { auth } = useAuth();

  if (!auth.hasPermission(permissions[model].view)) {
    return <Navigate replace to={routes.home} />;
  }

  return children;
};

/**
 * List of unrestricted routes (only authentication)
 */
const unprotectedRoutes = [
  { id: 'dashboard', Component: Dashboard }
];

/**
 * List of restricted routes by permission
 */
const protectedRoutes = [
  { id: 'facility', Component: Home }, /* TODO */
  { id: 'facilityType', Component: FacilityType },
  { id: 'facilityCategory', Component: FacilityCategory },
  { id: 'user', Component: Home }, /* TODO */
  { id: 'role', Component: UserRole },
  { id: 'donor', Component: Donors },
  { id: 'appointment', Component: Appointments },
  { id: 'donation', Component: BloodDonations },
  { id: 'test', Component: BloodTests },
  { id: 'processing', Component: Processing },
  { id: 'delivery', Component: Deliveries },
  { id: 'patient', Component: Patients },
  { id: 'request', Component: Requests },
  { id: 'crossMatching', Component: CrossMatching },
  { id: 'adverseEvent', Component: AdverseEvent }
];

/**
 * Component that handles the different app routes.
 *
 * @component
 */
const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path={routes.signin} element={<SignIn />} />

        <Route path='/' element={<AppLayout />}>
          <Route index element={<Home />} />

          {unprotectedRoutes.map(({ id, Component }) => (
            <Route
              key={id}
              path={routes[id]}
              element={<Component />}
            />
          ))}

          {protectedRoutes.map(({ id, Component }) => (
            <Route
              key={id}
              path={routes[id]}
              element={<ViewPermission model={id}><Component /></ViewPermission>}
            />
          ))}

          <Route path='*' element={<Navigate replace to={routes.home} />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
