import styled from 'styled-components';

import sideImage from '../../assets/images/people.png';
import { WhiteDiv } from '../../containers/styled/layout';

export const StyledWelcomeCon = styled(WhiteDiv)`
  text-align: center;
  margin-top: 0;
`;

export const StyledWelcome = styled.div`
  margin: 30px 0 36px;
  font-size: 14px;
`;

export const StyledBackgroundImg = styled.div`
  background-image: url(${sideImage});
  background-position: center center;
  background-size: cover;
  height: 100vh;
`;

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};

  .side-image {
    height: 100vh;
    width: 50vw;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;
