import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { choices } from '../../../constants/choices';
import { FilterModal } from '../../../containers/styled/alerts';
import { TertiaryButton } from '../../../containers/styled/buttons';
import {
  SelectPickerField
} from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import { listFacility } from '../../../controllers/reports/CommonController';
import { SelectDiv } from '../../generic/styled';

const messages = defineMessages({
  facilityPlaceholder: {
    id: 'report.filter.crossMatching.facility.placeholder',
    defaultMessage: 'Select Facility'
  },
  statusPlaceholder: {
    id: 'report.filter.crossMatching.status.placeholder',
    defaultMessage: 'Select Status'
  },
  matchPlaceholder: {
    id: 'report.filter.crossMatching.match.placeholder',
    defaultMessage: 'Select Match'
  },
  datePeriodPlaceholder: {
    id: 'report.filter.crossMatching.datePeriod.placeholder',
    defaultMessage: 'Select Date'
  }
});

/**
 * Cross Matching Filter screen
 *
 * Cross matching filter to display modal to filter data table.
 *
 * @component
 * @param {array} data              an array of objects representing the data to be displayed
 * @param {boolean} handleClose     handles modal close event
 * @param setFilteredData           sets filtered data to the data table
 * @param initialQuery              default data to the data table
 * @param setInitialQuery           sets initialQuery data to table before modal closes
 * @returns cross-matching filter modal.
 *
 */
const CrossMatchingFilter = ({ data, handleClose, setFilteredData, initialQuery, setInitialQuery }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [facilityData, setFacilityData] = React.useState([]);
  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    match: ''
  });

  React.useEffect(() => {
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => (entry.isActive && entry.suppliesBlood)) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setFacilityData(docs);
    });
  }, [db]);

  const filterTable = () => {
    const filteredData = data.filter(
      (entry) =>
        (!query.facility || entry.facility === query.facility) &&
        (!query.status || entry.status === query.status) &&
        (!query.match || entry.isMatch === query.match)
    );
    setFilteredData(filteredData);
    setInitialQuery(query);
    handleClose();
  };

  return (
    <FilterModal onClose={handleClose} size='xs'>
      <Modal.Body style={{ marginLeft: 26, marginRight: 26 }}>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.crossMatching.facility'
                  defaultMessage='Facility'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.facility}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.facilityPlaceholder)}
                  data={facilityData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, facility: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.crossMatching.status'
                  defaultMessage='Status'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.status}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.statusPlaceholder)}
                  data={choices.crossMatchingStatus.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, status: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.crossMatching.match'
                  defaultMessage='Match'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.match}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.matchPlaceholder)}
                  data={choices.match.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, match: value }))}
                />
              </SelectDiv>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24}>
              <TertiaryButton onClick={() => filterTable()}>
                <FormattedMessage
                  id='report.filter.button'
                  defaultMessage='Filter'
                />
              </TertiaryButton>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </FilterModal>
  );
};
export default CrossMatchingFilter;
