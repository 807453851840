import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import {
  bloodTypeMsg,
  genderMsg,
  statusMsg,
  translations
} from '../../../translations/en/common';
import { parseDate } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'patients.title',
    defaultMessage: 'Patients'
  },
  count: {
    id: 'patients.count.label',
    defaultMessage: 'Patient Record(s)'
  },
  searchPlaceHolder: {
    id: 'patients.search.placeholder',
    defaultMessage: 'Search Patient Records'
  },
  patientCode: {
    id: 'patients.patient.code',
    defaultMessage: 'Patient code'
  },
  firstName: {
    id: 'patients.first.name',
    defaultMessage: 'First name'
  },
  lastName: {
    id: 'patients.last.name',
    defaultMessage: 'Last name'
  },
  facility: {
    id: 'patients.facility',
    defaultMessage: 'Facility'
  },
  status: {
    id: 'patients.status',
    defaultMessage: 'Status'
  }
});

/**
 * Patient screen
 *
 * Patient to display data table showing patients.
 *
 * @component
 * @example
 * return (
 *    <Patients />
 * );
 *
 */
const Patients = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    gender: '',
    bloodType: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'patient')
      .find()
      .where({ isActive: true })
      .sort({ firstName: 1 })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          patientCode: entry.patientCode
            ? entry.patientCode
            : formatMessage(translations.notApplicable),
          name: entry.firstName
            ? entry.firstName
            : formatMessage(translations.notApplicable),
          lastName: entry.lastName
            ? entry.lastName
            : formatMessage(translations.notApplicable),
          gender: entry.gender
            ? formatMessage(genderMsg[`${entry.gender}`])
            : formatMessage(translations.notApplicable),
          dateOfBirth: entry.dateOfBirth
            ? parseDate(entry.dateOfBirth)
            : formatMessage(translations.notApplicable),
          bloodType: entry.bloodType
            ? formatMessage(bloodTypeMsg[entry.bloodType])
            : formatMessage(translations.notApplicable),
          languages:
            entry.languages.length > 0
              ? entry.languages.map((data) => data.name)
              : formatMessage(translations.notApplicable),
          status: entry.status
            ? formatMessage(statusMsg[entry.status])
            : formatMessage(translations.notApplicable),
          hospitalName: entry.hospital
            ? `${entry.hospital.name}`
            : formatMessage(translations.notApplicable),
          facility: entry.hospital
            ? entry.hospital.name
            : formatMessage(translations.notApplicable),
          documentId1Type: entry.documentId1Type
            ? entry.documentId1Type
            : formatMessage(translations.notApplicable),
          documentId1Number: entry.documentId1Number
            ? entry.documentId1Number
            : formatMessage(translations.notApplicable),
          documentId1IssuedBy: entry.documentId1IssuedBy
            ? entry.documentId1IssuedBy
            : formatMessage(translations.notApplicable),
          documentId1ExpireDate: entry.documentId1ExpireDate
            ? entry.documentId1ExpireDate
            : formatMessage(translations.notApplicable),
          documentId2Type: entry.documentId2Type
            ? entry.documentId2Type
            : formatMessage(translations.notApplicable),
          documentId2Number: entry.documentId2Number
            ? entry.documentId2Number
            : formatMessage(translations.notApplicable),
          documentId2IssuedBy: entry.documentId2IssuedBy
            ? entry.documentId2IssuedBy
            : formatMessage(translations.notApplicable),
          documentId2ExpireDate: entry.documentId2ExpireDate
            ? entry.documentId2ExpireDate
            : formatMessage(translations.notApplicable),
          email1: entry.email1
            ? entry.email1
            : formatMessage(translations.notApplicable),
          phone1Number: entry.phone1Number
            ? entry.phone1Number
            : formatMessage(translations.notApplicable),
          email2: entry.email2
            ? entry.email2
            : formatMessage(translations.notApplicable),
          phone2Number: entry.phone2Number
            ? entry.phone2Number
            : formatMessage(translations.notApplicable),
          address1: entry.address1
            ? entry.address1
            : formatMessage(translations.notApplicable),
          address1City: entry.address1City
            ? entry.address1City
            : formatMessage(translations.notApplicable),
          address1PostalCode: entry.address1PostalCode
            ? entry.address1PostalCode
            : formatMessage(translations.notApplicable),
          address2: entry.address2
            ? entry.address2
            : formatMessage(translations.notApplicable),
          address2City: entry.address2City
            ? entry.address2City
            : formatMessage(translations.notApplicable),
          address2PostalCode: entry.address2PostalCode
            ? entry.address2PostalCode
            : formatMessage(translations.notApplicable),
          createdAt: entry.createdAt
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'patients']}
        data={data}
        columns={[
          { dataKey: 'patientCode', label: messages.patientCode, flex: 1.5 },
          { dataKey: 'name', label: messages.firstName },
          { dataKey: 'lastName', label: messages.lastName },
          { dataKey: 'facility', label: messages.facility, flex: 2 },
          { dataKey: 'status', label: messages.status, flex: 1.5 }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
        isStatus
      />
    </MarginDiv>
  );
};

export default Patients;
