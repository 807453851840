import React from 'react';
import { useIntl } from 'react-intl';
import { useHref, useLocation } from 'react-router-dom';
import { Stack } from 'rsuite';
import Footer from 'rsuite/Footer';
import Nav from 'rsuite/Nav';
import Sidenav from 'rsuite/Sidenav';

import AppLogo from '../../assets/images/app-logo-white.svg';
import AppRelease from '../../containers/AppRelease';
import { useAuth } from '../../context/auth';
import { routeMessages } from './messages';
import { getRoutesByAuth } from './routes.js';
import { FormattedText, SidebarWrapper } from './styled';

/**
 * @private
 */
const Item = ({ id, icon, label }) => {
  const { formatMessage } = useIntl();

  return (
    <Stack alignItems='center' data-testid={`nav-item-${id}`}>
      {icon && <img src={icon} alt={label} style={{ marginRight: '10px' }} />}
      <FormattedText>
        {formatMessage(routeMessages[label])}
      </FormattedText>
    </Stack>
  );
};

/**
 * @private
 */
const SingleItem = ({ id, icon, label, route }) => (
  <Nav.Item eventKey={id} href={useHref(route)}>
    <Item id={id} icon={icon} label={label} />
  </Nav.Item>
);

/**
 * @private
 */
const GroupedItem = ({ id, icon, label, items }) => {
  return (
    <Nav.Menu
      eventKey={id}
      placement='rightStart'
      className={id}
      title={<Item icon={icon} label={label} />}
      data-testid={`nav-group-${id}`}
    >
      {items.map((item) => (<SingleItem key={item.id} {...item} />))}
    </Nav.Menu>
  );
};

/**
 * Component that renders the navigation bar with the route links.
 *
 * @component
 * @example
 * return (
 *    <LeftSideBar />
 * );
 */
const LeftSideBar = () => {
  const location = useLocation();
  const { auth } = useAuth();

  const [userRoutes, setUserRoutes] = React.useState(getRoutesByAuth(auth));
  const [openKeys, setOpenKeys] = React.useState([]);

  React.useEffect(() => {
    setUserRoutes(getRoutesByAuth(auth));
  }, [auth]);

  React.useEffect(() => {
    setOpenKeys(location.pathname.slice(1).split('/'));
  }, [location]);

  return (
    <SidebarWrapper data-testid='navbar'>
      <Sidenav
        openKeys={openKeys}
        onOpenChange={(entries) => { setOpenKeys(entries.slice(-1)); }}
      >
        <Sidenav.Header align='middle'>
          <img src={AppLogo} width='164' height='66' className='logo' alt='Biskit' />
        </Sidenav.Header>

        <Sidenav.Body>
          <Nav>
            {userRoutes.map((entry) => entry.items
              ? <GroupedItem key={entry.id} {...entry} />
              : <SingleItem key={entry.id} {...entry} />
            )}
          </Nav>
        </Sidenav.Body>
      </Sidenav>

      <Footer>
        <AppRelease />
      </Footer>
    </SidebarWrapper>
  );
};

export default LeftSideBar;
