import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { translations } from '../../../translations/en/common';
import { parseDate, parseTime } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'appointment.title',
    defaultMessage: 'Appointments'
  },
  count: {
    id: 'appointment.count.label',
    defaultMessage: 'Appointment(s)'
  },
  searchPlaceHolder: {
    id: 'appointment.search.placeholder',
    defaultMessage: 'Search Appointment'
  },
  appointmentId: {
    id: 'appointment.id',
    defaultMessage: 'Appointment ID'
  },
  donorCode: {
    id: 'appointment.donor.code',
    defaultMessage: 'Donor Code'
  },
  donorName: {
    id: 'appointment.donor.name',
    defaultMessage: 'Donor Name'
  },
  dateTime: {
    id: 'appointment.date-time',
    defaultMessage: 'Date & Time'
  },
  facility: {
    id: 'appointment.facility',
    defaultMessage: 'Facility'
  }
});

/**
 * Appointments screen
 *
 * Appointments to display data table showing appointments.
 *
 * @component
 * @example
 * return (
 *    <Appointments />
 * );
 *
 */
const Appointments = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'appointment')
      .find()
      .where({ isActive: true })
      .sort({ dateTime: 'asc' })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          donorCode: entry.donor
            ? entry.donor.donorCode
            : formatMessage(translations.notApplicable),
          name: entry.donor
            ? entry.donor.fullName
            : formatMessage(translations.notApplicable),
          dateTime: entry.dateTime
            ? parseDate(entry.dateTime) + ' : ' + parseTime(entry.dateTime)
            : formatMessage(translations.notApplicable),
          facility: entry.bloodDonationCenter
            ? entry.bloodDonationCenter.name
            : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'appointment']}
        data={data}
        columns={[
          { dataKey: 'donorCode', label: messages.donorCode },
          { dataKey: 'name', label: messages.donorName },
          { dataKey: 'dateTime', label: messages.dateTime },
          { dataKey: 'facility', label: messages.facility }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};

export default Appointments;
