import { Dropdown, Panel, Table } from 'rsuite';
import styled from 'styled-components';

import { PrimaryHoloButton } from '../../containers/styled/buttons';

export const StyledPanel = styled(Panel)`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.colors.grey_border};
`;

export const StyledTable = styled(Table)`
  .rs-table-row:not(.rs-table-row-header) {
    &:hover {
      .rs-table-cell {
        background-color: ${(props) => props.theme.colors.hover_color} !important;
      }
    }
  }
`;

export const StyledCell = styled(Table.Cell)`
  font-size: ${(props) => props.theme.font.sizes.s};
`;

export const SelectDiv = styled.div`
  .select-md .rs-picker-toggle.rs-btn {
    height: 38px !important;
    padding: 8px 18px !important;
    font-size: 12px;
  }

  .select-md .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .select-md .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px !important;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn {
    height: 58px;
    padding: 16px 24px;
    border-radius: 4px;
  }

  .rs-picker,
  .rs-picker-toggle {
    width: 100%;
  }

  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 18px;
  }

  .rs-picker-toggle-value {
    color: ${(props) => props.theme.colors.grey_granite} !important;
  }
`;

export const activeState = {
  backgroundColor: '#D1FAE5',
  color: '#008040'
};

export const upcomingState = {
  backgroundColor: '#EFE8C3',
  color: '#71641E'
};

export const disabledState = {
  backgroundColor: '#EDECEC',
  color: '#737373'
};

export const cancelledState = {
  backgroundColor: '#FEE2E2',
  color: '#E44931'
};

export const completedState = {
  backgroundColor: '#A7F3D0',
  color: '#008040'
};

export const otherState = {
  backgroundColor: '#D9D9D9',
  color: '#62707b'
};

export const DropdownItem = styled(Dropdown.Item)`
  padding: 10px 12px;

  &.rs-dropdown-item:focus-visible,
  &.rs-dropdown-item.rs-dropdown-item-focus {
    background-color: ${(props) => props.theme.colors.hover_color};
    color: ${(props) => props.theme.colors.font};
  }
`;

export const Chips = styled.div`
  position: relative;
  bottom: 3px;
  border-radius: 16px;
  padding: 4px 0;
  width: 94px;
  text-align: center;
  font-size: ${(props) => props.theme.font.sizes.xs};
`;

export const DropdownList = styled(Dropdown)`
  width: 100%;
  padding: 0;
  border-radius: 4px;

  li {
    color: ${(props) => props.theme.colors.font};
    line-height: 16px;
    padding: 16px;
  }

  a:hover {
    text-decoration: none;
  }

  .rs-dropdown-item:focus-visible,
  .rs-dropdown-item.rs-dropdown-item-focus {
    background-color: ${(props) => props.theme.colors.hover_color};
    color: ${(props) => props.theme.colors.font};
  }
`;

export const DropdownButton = styled(PrimaryHoloButton)`
  margin-right: 0;
  padding: 0.8rem 1.18rem;
  font-weight: ${(props) => props.theme.font.weights.regular};

  span {
    margin: 0 8px;
  }
`;

export const StyledDesc = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.font_dark};
  margin-top: 18px;
`;
