import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { RELEASE, VERSION } from '../utils/environment';

const messages = defineMessages({
  version: {
    id: 'app.version',
    defaultMessage: 'Version'
  }
});

/**
 * Component that renders the current release.
 *
 * @component
 * @example
 * return (
 *    <AppRelease />
 * );
 */
const AppRelease = () => {
  const { formatMessage } = useIntl();

  return (
    <div
      data-testid='app-release'
      className='app-version'
      title={`${formatMessage(messages.version)}: ${VERSION}`}
    >
      <FormattedMessage
        id='app.release'
        defaultMessage='Release'
      />:  {RELEASE}
    </div>
  );
};

export default AppRelease;
