import { v4 as uuid4 } from 'uuid';

import { getCollection } from '../../rxdb/collections';

/**
 * List All User Role (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listUserRole = async (db) => {
  return getCollection(db, 'role')
    .find()
    .exec()
    .then((documents) => {
      return documents.map((entry) => ({
        id: entry.id,
        name: entry.name,
        isActive: entry.isActive
      }));
    });
};

/**
 * Creates User Role
 *
 * @param {*} db    RxDB database
 * @param {*} data  user role data
 * @returns Promise indicating if action succeed
 */
export const createUserRole = (db, data) => {
  const doc = {
    id: uuid4(),
    name: data.name,
    details: data.details,
    isActive: true,
    // Audit fields
    lastmodifiedAt: new Date().toISOString()
  };

  return getCollection(db, 'role')
    .insert(doc)
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Updates User Role
 *
 * @param {*} db    RxDB database
 * @param {*} data  user role updated data (along with the id)
 * @returns Promise indicating if action succeed
 */
export const updateUserRole = (db, data) => {
  const { id, ...updated } = data;
  const doc = getCollection(db, 'role').findOne({ selector: { id } });

  return doc
    .update({
      $set: {
        ...updated,
        // Audit fields
        lastmodifiedAt: new Date().toISOString()
      }
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Deletes User Role
 *
 * @param {*} db    RxDB database
 * @param {*} data  user role data
 * @returns Promise indicating if action succeed
 */
export const deleteUserRole = (db, data) => {
  return updateUserRole(db, {
    id: data.id,
    // workaround to skip name uniqueness constraint
    name: `${data.name} :: ${data.id} :: ${new Date().toISOString()}`,
    isActive: false
  });
};
