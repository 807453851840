import { defineMessages } from 'react-intl';

const messages = {
  gender: defineMessages({
    male: {
      id: 'choice.gender.male',
      defaultMessage: 'Male'
    },
    female: {
      id: 'choice.gender.female',
      defaultMessage: 'Female'
    }
  }),

  bloodType: defineMessages({
    'o-': {
      id: 'choice.bloodType.o-',
      defaultMessage: 'O-'
    },
    'o+': {
      id: 'choice.bloodType.o+',
      defaultMessage: 'O+'
    },
    'ab-': {
      id: 'choice.bloodType.ab-',
      defaultMessage: 'AB-'
    },
    'ab+': {
      id: 'choice.bloodType.ab+',
      defaultMessage: 'AB+'
    },
    'a-': {
      id: 'choice.bloodType.a-',
      defaultMessage: 'A-'
    },
    'a+': {
      id: 'choice.bloodType.a+',
      defaultMessage: 'A+'
    },
    'b-': {
      id: 'choice.bloodType.b-',
      defaultMessage: 'B-'
    },
    'b+': {
      id: 'choice.bloodType.b+',
      defaultMessage: 'B+'
    }
  }),

  donationStatus: defineMessages({
    initial: {
      id: 'choice.donationStatus.initial',
      defaultMessage: 'Initial'
    },
    picked: {
      id: 'choice.donationStatus.picked',
      defaultMessage: 'Picked'
    },
    'in-transit': {
      id: 'choice.donationStatus.intransit',
      defaultMessage: 'In-transit'
    },
    collected: {
      id: 'choice.donationStatus.collected',
      defaultMessage: 'Collected'
    },
    testing: {
      id: 'choice.donationStatus.testing',
      defaultMessage: 'Testing'
    },
    tested: {
      id: 'choice.donationStatus.tested',
      defaultMessage: 'Tested'
    },
    discarded: {
      id: 'choice.donationStatus.discarded',
      defaultMessage: 'Discarded'
    },
    accepted: {
      id: 'choice.donationStatus.accepted',
      defaultMessage: 'Accepted'
    },
    processing: {
      id: 'choice.donationStatus.processing',
      defaultMessage: 'Processing'
    },
    processed: {
      id: 'choice.donationStatus.processed',
      defaultMessage: 'Processed'
    },
    completed: {
      id: 'choice.donationStatus.completed',
      defaultMessage: 'Completed'
    }
  }),

  testType: defineMessages({
    'blood-type-test': {
      id: 'choice.testType.bloodtypetest',
      defaultMessage: 'Blood Type Test'
    },
    'donation-test': {
      id: 'choice.testType.donationtest',
      defaultMessage: 'Donation Test'
    },
    'in-depth-test': {
      id: 'choice.testType.indepthtest',
      defaultMessage: 'In Depth Test'
    },
    'initial-test': {
      id: 'choice.testType.initialtest',
      defaultMessage: 'Initial Test'
    },
    'processing-test': {
      id: 'choice.testType.processingtest',
      defaultMessage: 'Processing Test'
    }
  }),

  testResult: defineMessages({
    pass: {
      id: 'choice.testResult.pass',
      defaultMessage: 'Pass'
    },
    fail: {
      id: 'choice.testResult.fail',
      defaultMessage: 'Fail'
    }
  }),

  testStatus: defineMessages({
    ongoing: {
      id: 'choice.testStatus.ongoing',
      defaultMessage: 'Ongoing'
    },
    completed: {
      id: 'choice.testStatus.completed',
      defaultMessage: 'Completed'
    }
  }),

  deliveryStatus: defineMessages({
    completed: {
      id: 'choice.deliveryStatus.completed',
      defaultMessage: 'Completed'
    },
    scheduled: {
      id: 'choice.deliveryStatus.scheduled',
      defaultMessage: 'Scheduled'
    },
    upcoming: {
      id: 'choice.deliveryStatus.upcoming',
      defaultMessage: 'Upcoming'
    }
  }),

  priority: defineMessages({
    low: {
      id: 'choice.priority.low',
      defaultMessage: 'Low'
    },
    medium: {
      id: 'choice.priority.medium',
      defaultMessage: 'Medium'
    },
    high: {
      id: 'choice.priority.high',
      defaultMessage: 'High'
    }
  }),

  patientStatus: defineMessages({
    admitted: {
      id: 'choice.patientStatus.admitted',
      defaultMessage: 'Admitted'
    },
    discharged: {
      id: 'choice.patientStatus.discharged',
      defaultMessage: 'Discharged'
    },
    other: {
      id: 'choice.patientStatus.other',
      defaultMessage: 'Other'
    }
  }),

  requestStatus: defineMessages({
    active: {
      id: 'choice.requestStatus.active',
      defaultMessage: 'Active'
    },
    upcoming: {
      id: 'choice.requestStatus.upcoming',
      defaultMessage: 'Upcoming'
    },
    ready: {
      id: 'choice.requestStatus.ready',
      defaultMessage: 'Ready'
    },
    ongoing: {
      id: 'choice.requestStatus.ongoing',
      defaultMessage: 'Ongoing'
    },
    'in-progress': {
      id: 'choice.requestStatus.inprogrss',
      defaultMessage: 'In Progress'
    },
    completed: {
      id: 'choice.requestStatus.completed',
      defaultMessage: 'Completed'
    },
    cancelled: {
      id: 'choice.requestStatus.cancelled',
      defaultMessage: 'Cancelled'
    },
    'on-hold': {
      id: 'choice.requestStatus.onhold',
      defaultMessage: 'On Hold'
    }
  }),

  crossMatchingStatus: defineMessages({
    scheduled: {
      id: 'choice.crossMatchingStatus.scheduled',
      defaultMessage: 'Scheduled'
    },
    active: {
      id: 'choice.crossMatchingStatus.active',
      defaultMessage: 'Active'
    },
    upcoming: {
      id: 'choice.crossMatchingStatus.upcoming',
      defaultMessage: 'Upcoming'
    },
    ready: {
      id: 'choice.crossMatchingStatus.ready',
      defaultMessage: 'Ready'
    },
    ongoing: {
      id: 'choice.crossMatchingStatus.ongoing',
      defaultMessage: 'Ongoing'
    },
    'in-progress': {
      id: 'choice.crossMatchingStatus.inprogress',
      defaultMessage: 'In Progress'
    },
    completed: {
      id: 'choice.crossMatchingStatus.completed',
      defaultMessage: 'Completed'
    },
    cancelled: {
      id: 'choice.crossMatchingStatus.cancelled',
      defaultMessage: 'Cancelled'
    },
    'on-hold': {
      id: 'choice.crossMatchingStatus.onhold',
      defaultMessage: 'On Hold'
    }
  }),

  yesNo: defineMessages({
    true: {
      id: 'choice.yesNo.true',
      defaultMessage: 'Yes'
    },
    false: {
      id: 'choice.yesNo.false',
      defaultMessage: 'No'
    }
  })
};

export const choices = {
  gender: [
    {
      id: 'M',
      name: messages.gender.male
    },
    {
      id: 'F',
      name: messages.gender.female
    }
  ],

  bloodType: [
    {
      id: 'O-',
      name: messages.bloodType['o-']
    },
    {
      id: 'O+',
      name: messages.bloodType['o+']
    },
    {
      id: 'AB-',
      name: messages.bloodType['ab-']
    },
    {
      id: 'AB+',
      name: messages.bloodType['ab+']
    },
    {
      id: 'A-',
      name: messages.bloodType['a-']
    },
    {
      id: 'A+',
      name: messages.bloodType['a+']
    },
    {
      id: 'B-',
      name: messages.bloodType['b-']
    },
    {
      id: 'B+',
      name: messages.bloodType['b+']
    }
  ],

  donationStatus: [
    {
      id: 'Initial',
      name: messages.donationStatus.initial
    },
    {
      id: 'Picked',
      name: messages.donationStatus.picked
    },
    {
      id: 'In-transit',
      name: messages.donationStatus['in-transit']
    },
    {
      id: 'Collected',
      name: messages.donationStatus.collected
    },
    {
      id: 'Testing',
      name: messages.donationStatus.testing
    },
    {
      id: 'tested',
      name: messages.donationStatus.tested
    },
    {
      id: 'Discarded',
      name: messages.donationStatus.discarded
    },
    {
      id: 'Accepted',
      name: messages.donationStatus.accepted
    },
    {
      id: 'Processing',
      name: messages.donationStatus.processing
    },
    {
      id: 'Processed',
      name: messages.donationStatus.processed
    },
    {
      id: 'Completed',
      name: messages.donationStatus.completed
    }
  ],

  testType: [
    {
      id: 'Blood Type Test',
      name: messages.testType['blood-type-test']
    },
    {
      id: 'Donation Test',
      name: messages.testType['donation-test']
    },
    {
      id: 'In Depth Test',
      name: messages.testType['in-depth-test']
    },
    {
      id: 'Initial Test',
      name: messages.testType['initial-test']
    },
    {
      id: 'Processing Test',
      name: messages.testType['processing-test']
    }
  ],

  testResult: [
    {
      id: 'pass',
      name: messages.testResult.pass
    },
    {
      id: 'fail',
      name: messages.testResult.fail
    }
  ],

  testStatus: [
    {
      id: 'ongoing',
      name: messages.testStatus.ongoing
    },
    {
      id: 'completed',
      name: messages.testStatus.completed
    }
  ],

  deliveryStatus: [
    {
      id: 'Completed',
      name: messages.deliveryStatus.completed
    },
    {
      id: 'Scheduled',
      name: messages.deliveryStatus.scheduled
    },
    {
      id: 'Upcoming',
      name: messages.deliveryStatus.upcoming
    }
  ],

  priority: [
    {
      id: 'Low',
      name: messages.priority.low
    },
    {
      id: 'Medium',
      name: messages.priority.medium
    },
    {
      id: 'High',
      name: messages.priority.high
    }
  ],

  patientStatus: [
    {
      id: 'Admitted',
      name: messages.patientStatus.admitted
    },
    {
      id: 'Discharged',
      name: messages.patientStatus.discharged
    },
    {
      id: 'Other',
      name: messages.patientStatus.other
    }
  ],

  requestStatus: [
    {
      id: 'Active',
      name: messages.requestStatus.active
    },
    {
      id: 'Upcoming',
      name: messages.requestStatus.upcoming
    },
    {
      id: 'Ready',
      name: messages.requestStatus.ready
    },
    {
      id: 'Ongoing',
      name: messages.requestStatus.ongoing
    },
    {
      id: 'In Progress',
      name: messages.requestStatus['in-progress']
    },
    {
      id: 'Completed',
      name: messages.requestStatus.completed
    },
    {
      id: 'Cancelled',
      name: messages.requestStatus.cancelled
    },
    {
      id: 'On Hold',
      name: messages.requestStatus['on-hold']
    }
  ],

  crossMatchingStatus: [
    {
      id: 'Scheduled',
      name: messages.crossMatchingStatus.scheduled
    },
    {
      id: 'Active',
      name: messages.crossMatchingStatus.active
    },
    {
      id: 'Upcoming',
      name: messages.crossMatchingStatus.upcoming
    },
    {
      id: 'Ready',
      name: messages.crossMatchingStatus.ready
    },
    {
      id: 'Ongoing',
      name: messages.crossMatchingStatus.ongoing
    },
    {
      id: 'In Progress',
      name: messages.crossMatchingStatus['in-progress']
    },
    {
      id: 'Completed',
      name: messages.crossMatchingStatus.completed
    },
    {
      id: 'Cancelled',
      name: messages.crossMatchingStatus.cancelled
    },
    {
      id: 'On Hold',
      name: messages.crossMatchingStatus['on-hold']
    }
  ],

  match: [
    {
      id: 'Yes',
      name: messages.yesNo.true
    },
    {
      id: 'No',
      name: messages.yesNo.false
    }
  ]
};
