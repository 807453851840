import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for language/dialect data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listLanguages(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode
        name
        details

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for ethnic group data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertLanguage(
        input: {
          ${getMutationStringValue(doc, 'id')}

          ${getMutationStringValue(doc, 'name')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize language/dialect data with GraphQL endpoint
 */
export const syncLanguage = (...args) => {
  return buildReplication(
    ...args,
    'language',
    { pullBuilder, pullQuery: 'listLanguages' },
    { pushBuilder }
  );
};
