import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationArrayNestedValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for delivery data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listDeliveries(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        dueTime
        priority
        status
        details

        pickupPoint { id name address }
        destination { id name address }
        driver { username name }
        vehicle { id plateNumber modelMake }

        consumables { id name }
        equipments { id name }
        healthCareWorkers { username name }

        bloodProducts {
          id
          icode
          status
          expiryDate
          bloodType
          volume
          bloodProductType { id name }
          facility { id name address }
        }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for delivery data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertDelivery(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dueTime')}
          ${getMutationStringValue(doc, 'priority')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'pickupPoint')}
          ${getMutationNestedValue(doc, 'destination')}
          ${getMutationNestedValue(doc, 'driver', 'username')}
          ${getMutationNestedValue(doc, 'vehicle')}

          ${getMutationArrayNestedValue(doc, 'consumableIds', 'consumables')}
          ${getMutationArrayNestedValue(doc, 'equipmentIds', 'equipments')}
          ${getMutationArrayNestedValue(doc, 'healthCareWorkerNames', 'healthCareWorkers', 'username')}

          ${getMutationArrayNestedValue(doc, 'bloodProductIds', 'bloodProducts')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize delivery data with GraphQL endpoint
 */
export const syncDelivery = (...args) => {
  return buildReplication(
    ...args,
    'delivery',
    { pullBuilder, pullQuery: 'listDeliveries' },
    { pushBuilder }
  );
};
