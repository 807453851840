import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationArrayNestedValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for donation data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listDonations(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        date
        status
        details

        length
        width
        volume

        bloodDonationCenter { id name address stockUnit { id name address } }
        donor { id donorCode fullName personName gender bloodType }
        collectionTask { id dueTime status priority }

        consumables { id name }
        equipments { id name }
        healthCareWorkers { username name }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for donation data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertDonation(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'date')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNumberValue(doc, 'length')}
          ${getMutationNumberValue(doc, 'width')}
          ${getMutationNumberValue(doc, 'volume')}

          ${getMutationNestedValue(doc, 'bloodDonationCenter')}
          ${getMutationNestedValue(doc, 'donor')}
          ${getMutationNestedValue(doc, 'collectionTask')}

          ${getMutationArrayNestedValue(doc, 'consumableIds', 'consumables')}
          ${getMutationArrayNestedValue(doc, 'equipmentIds', 'equipments')}
          ${getMutationArrayNestedValue(doc, 'healthCareWorkerNames', 'healthCareWorkers', 'username')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize donation data with GraphQL endpoint
 */
export const syncDonation = (...args) => {
  return buildReplication(
    ...args,
    'donation',
    { pullBuilder, pullQuery: 'listDonations' },
    { pushBuilder }
  );
};
