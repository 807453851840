import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationArrayNestedValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for transfusion data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listTransfusions(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        dueTime
        status
        details

        bloodProductType { id name shelfLife shelfLifeUnit }
        bloodProduct {
          id icode volume bloodType expiryDate
          bloodProductType { id name shelfLife shelfLifeUnit }
        }
        hospital {
          id name address
          bloodBank { id name address stockUnit { id name address } }
        }
        patient { id patientCode fullName personName gender bloodType }

        consumables { id name }
        equipments { id name }
        healthCareWorkers { username name }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for transfusion data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertTransfusion(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dueTime')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'bloodProductType')}
          ${getMutationNestedValue(doc, 'bloodProduct')}
          ${getMutationNestedValue(doc, 'patient')}
          ${getMutationNestedValue(doc, 'hospital')}

          ${getMutationArrayNestedValue(doc, 'consumableIds', 'consumables')}
          ${getMutationArrayNestedValue(doc, 'equipmentIds', 'equipments')}
          ${getMutationArrayNestedValue(doc, 'healthCareWorkerNames', 'healthCareWorkers', 'username')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize transfusion data with GraphQL endpoint
 */
export const syncTransfusion = (...args) => {
  return buildReplication(
    ...args,
    'transfusion',
    { pullBuilder, pullQuery: 'listTransfusions' },
    { pushBuilder }
  );
};
