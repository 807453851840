import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { statusMsg, translations } from '../../../translations/en/common';
import { parseDate } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'processing.title',
    defaultMessage: 'Processing'
  },
  count: {
    id: 'processing.count.label',
    defaultMessage: 'Processing Record(s)'
  },
  searchPlaceHolder: {
    id: 'processing.search.placeholder',
    defaultMessage: 'Search Processing Records'
  },
  donorCode: {
    id: 'processing.donor.code',
    defaultMessage: 'Donor Code'
  },
  bloodProduct: {
    id: 'processing.blood.product',
    defaultMessage: 'Blood Product'
  },
  volume: {
    id: 'processing.volume',
    defaultMessage: 'Volume'
  },
  facility: {
    id: 'processing.facility',
    defaultMessage: 'Facility'
  }
});

/**
 * Processing screen
 *
 * Processing to display data table showing processings.
 *
 * @component
 * @example
 * return (
 *    <Processing />
 * );
 *
 */
const Processing = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    bloodProductType: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'processing')
      .find()
      .where({ isActive: true })
      .sort({ volume: 1 })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          donorCode: entry.donation.donor.donorCode
            ? entry.donation.donor.donorCode
            : formatMessage(translations.notApplicable),
          name: entry.bloodProductType.name
            ? entry.bloodProductType.name
            : formatMessage(translations.notApplicable),
          dueTime: entry.dueTime
            ? parseDate(entry.dueTime)
            : formatMessage(translations.notApplicable),
          status: entry.status
            ? formatMessage(statusMsg[entry.status])
            : formatMessage(translations.notApplicable),
          volume: entry.volume
            ? entry.volume
            : formatMessage(translations.notApplicable),
          facility: entry.donation.bloodDonationCenter.name
            ? entry.donation.bloodDonationCenter.name
            : formatMessage(translations.notApplicable),
          healthCareWorkers:
            entry.healthCareWorkers.length > 0
              ? entry.healthCareWorkers.map((data) => data.name)
              : formatMessage(translations.notApplicable),
          equipments:
            entry.equipments.length > 0
              ? entry.equipments
              : formatMessage(translations.notApplicable),
          consumables:
            entry.consumables.length > 0
              ? entry.consumables
              : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'processing']}
        data={data}
        columns={[
          { dataKey: 'donorCode', label: messages.donorCode, flex: 2 },
          { dataKey: 'name', label: messages.bloodProduct, flex: 2 },
          { dataKey: 'volume', label: messages.volume },
          { dataKey: 'facility', label: messages.facility, flex: 2 }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};

export default Processing;
