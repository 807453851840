import React from 'react';

import { MarginLeft } from '../../containers/styled/layout';
import Dashboard from './dashboard';

const DashboardOverview = () => {
  return (
    <MarginLeft>
      <Dashboard />
    </MarginLeft>
  );
};

export default DashboardOverview;
