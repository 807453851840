import { IntlProvider } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';

import { AuthProvider } from '../context/auth';
import { DbProvider } from '../context/database';
import { Theme } from './styled/theme';

/**
 * Include App component basic styles:
 * - Font family
 * - Font size
 * - Font color
 *
 * @private
 */
const BasicWrapper = styled.div`
  font-family: ${props => props.theme.font.family};
  font-size: ${props => props.theme.font.size};
  color: ${props => props.theme.colors.font};
  background-color: ${props => props.theme.colors.bg};
  margin: 0;
`;

/**
 * AppWrapper component.
 *
 * Wraps the children with:
 * - the IntlProvider component to enable i18n and L11n
 * - the ThemeProvider component to enable styled components theme
 * - the DbProvider component to handle database connection
 * - the AuthProvider component to handle authentication and authorization
 *
 * @component
 * @example
 * const root = createRoot(document.getElementById('root'));
 *
 * root.render(
 *   <React.StrictMode>
 *     <AppWrapper>
 *       <App />
 *     </AppWrapper>
 *   </React.StrictMode>
 * );
 */
const AppWrapper = ({ children }) => (
  <IntlProvider defaultLocale='en' locale={navigator.locale || 'en'}>
    <ThemeProvider theme={Theme}>
      <BasicWrapper>
        <DbProvider>
          <AuthProvider>
            {children}
          </AuthProvider>
        </DbProvider>
      </BasicWrapper>
    </ThemeProvider>
  </IntlProvider>
);

export default AppWrapper;
