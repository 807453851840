import styled from 'styled-components';

const Button = styled.button`
  border-radius: ${props => props.theme.radius.sizes.xxl};
  font-family: ${props => props.theme.font.family};
  font-size: ${props => props.theme.font.sizes.s};
  font-weight: ${props => props.theme.font.weights.bold};
  border: 1px solid;
  padding: 1.1rem;

  span {
    margin: 0 10px 0 0;
  }
`;

export const PrimaryButton = styled(Button)`
  background: ${props => props.disabled ? props.theme.colors.secondary : props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
`;

export const PrimaryHoloButton = styled(Button)`
  background: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  margin-right: 10px;
`;

export const SecondaryButton = styled(Button)`
  background: ${props => props.theme.colors.secondary};
  border-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
`;

export const TertiaryButton = styled(Button)`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  font-weight: ${(props) => props.theme.font.weights.regular};
  padding: 0.8rem;
  width: 100%;
`;

export const FilterButton = styled(Button)`
  background: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.grey_border};
  color: ${props => props.theme.colors.font};
  font-size: ${props => props.theme.font.sizes.xs};
  font-weight: ${(props) => props.theme.font.weights.regular};
  border-radius: 4px;
  padding: 0.55rem 0.875rem;
`;
