import { FormattedMessage } from 'react-intl';
import { Popover, Stack, Whisper } from 'rsuite';
import Nav from 'rsuite/Nav';

import chevronIcon from '../../assets/svg-images/chevron-down.svg';
import logoutIcon from '../../assets/svg-images/logout.svg';
import userIcon from '../../assets/svg-images/user-icon.svg';
import { FaRegularIcon } from '../../containers/styled/layout';
import { useAuth } from '../../context/auth';
import { StyledSpan, Wrapper } from './styled';

/**
 * Component that renders current logged in user data
 *
 * @component
 */
const UserInfo = () => {
  const { auth: { user } = {}, logout } = useAuth();

  if (!user) return '';

  const Item = () => {
    return (
      <Stack alignItems='center'>
        <img src={logoutIcon} alt='logout' style={{ marginRight: '10px' }} />
        <StyledSpan>
          <FormattedMessage id='menu.header.logout' defaultMessage='logout' />
        </StyledSpan>
      </Stack>
    );
  };

  const RenderMenu = ({ className }, ref) => {
    return (
      <Popover
        style={{ boxShadow: 'none' }}
        ref={ref}
        className={className}
        full
      >
        <Nav.Item onClick={logout}>
          <Item />
        </Nav.Item>
      </Popover>
    );
  };

  return (
    <Wrapper data-testid='user-info'>
      <Nav pullRight>
        <Nav.Item icon={<FaRegularIcon icon='bell' />}>
          {/* FIXME: This appears only with notifications */}
        </Nav.Item>

        <Nav.Item
          icon={<img src={userIcon} alt='' />}
          data-testid={`user-info-${user.username}`}
        >
          {user.name}
          {user.role && <span>&nbsp;({user.role.name})</span>}
        </Nav.Item>

        <Whisper placement='bottomEnd' trigger='click' speaker={RenderMenu}>
          <Nav.Item icon={<img src={chevronIcon} alt='chevron' data-testid='user-info-logout' />} />
        </Whisper>
      </Nav>
    </Wrapper>
  );
};

export default UserInfo;
