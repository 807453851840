import { InputGroup } from 'rsuite';

import { StyledInput, StyledInputGroup } from './styled';

export const SearchInput = (props) => (
  <StyledInputGroup height='48px' inside>
    <InputGroup.Button style={{ padding: '23px 13px' }}>
      <i className='fas fa-1x fa-search' />
    </InputGroup.Button>
    <StyledInput {...props} />
  </StyledInputGroup>
);

export const TextField = ({ ...props }) => (
  <StyledInputGroup height='58px' inside>
    <StyledInput {...props} />
  </StyledInputGroup>
);

export const TextFieldXs = ({ ...props }) => (
  <StyledInputGroup height='38px' inside>
    <StyledInput {...props} />
  </StyledInputGroup>
);
