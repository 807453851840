import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationStringsValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for user role data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listRoles(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        name
        details

        permissionKeys

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for user role data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertRole(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'name')}
          ${getMutationStringValue(doc, 'details')}


          ${getMutationStringsValue(doc, 'permissionsKeys', 'permissions')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize user role data with GraphQL endpoint
 */
export const syncRole = (...args) => {
  return buildReplication(
    ...args,
    'role',
    { pullBuilder, pullQuery: 'listRoles' },
    { pushBuilder }
  );
};
