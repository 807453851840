import { REPLICATION_SIZE } from '../../../utils/constants';
import { auditFields, buildReplication } from '../utils';

/**
 * Pull builder for questionnaire data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listQuestionnaires(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        name
        details

        code
        version
        definition

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Synchronize questionnaire data with GraphQL endpoint
 */
export const syncQuestionnaire = (...args) => {
  return buildReplication(
    ...args,
    'questionnaire',
    { pullBuilder, pullQuery: 'listQuestionnaires' }
  );
};
