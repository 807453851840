import { auditFields, operations, types } from '../common';

/**
 * Entity schema
 */
const schema = {
  version: 0,

  title: 'Donation',
  description: 'Blood donation',

  primaryKey: 'id',
  type: 'object',
  properties: {
    id: types.ID,
    icode: types.STRING_INDEX,

    date: types.DATE_INDEX,
    status: types.STRING_INDEX,
    details: types.STRING,

    length: types.NUMBER,
    width: types.NUMBER,
    volume: types.NUMBER,

    bloodDonationCenter: types.OBJECT,
    donor: types.OBJECT,
    collectionTask: types.OBJECT,

    consumables: types.ARRAY,
    equipments: types.ARRAY,
    healthCareWorkers: types.ARRAY,

    ...auditFields
  },

  required: ['id', 'date', 'bloodDonationCenter', 'donor'],
  indexes: [
    ['date']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: function (data) {
    return operations.add(this, data);
  },

  /**
   * Updates instance
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
