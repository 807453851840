import { auditFields, operations, types } from '../common';

/**
 * Entity schema
 */
const schema = {
  version: 0,

  title: 'Crisis event',
  description: 'Crisis event',

  primaryKey: 'id',
  type: 'object',
  properties: {
    id: types.ID,
    icode: types.STRING_INDEX,

    startTime: types.DATE_TIME_INDEX,
    endTime: types.DATE_TIME,

    severity: types.STRING_INDEX, // category
    status: types.STRING_INDEX, // activity

    location: types.STRING,
    latitude: types.STRING, // float parsed as string
    longitude: types.STRING, // float parsed as string

    details: types.STRING,

    // Audit fields
    ...auditFields
  },

  required: ['id', 'startTime', 'endTime', 'severity', 'status'],
  indexes: [
    ['startTime']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * Also updates full and person names
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: function (data) {
    return operations.add(this, data);
  },

  /**
   * Updates instance
   *
   * Also updates full and person names
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
