import { v4 as uuid4 } from 'uuid';

import { getCollection } from '../../rxdb/collections';

/**
 * List All Facility
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listFacility = async (db) => {
  const olderDocuments = await getCollection(db, 'facility').find()
    .exec()
    .then((documents) => {
      const allData = documents.map((entry) => ({
        name: entry.name,
        city: entry.city,
        typeId: entry.type.id,
        typeName: entry.type.name,
        categoryId: entry.category.id,
        categoryName: entry.category.name,
        createdAt: entry.createdAt
      }));
      return allData;
    });
  return olderDocuments;
};

/**
 * Creates Facility category
 *
 * @param {*} db    RxDB database
 * @param {*} data  facility category data
 * @returns Promise indicating if action succeed
 */
export const createFacility = (db, data) => {
  const doc = {
    id: uuid4(),
    name: data.name,
    city: data.city,
    address: data.address,
    createdAt: new Date().toISOString(),
    lastmodifiedAt: new Date().toISOString(),
    createdBy: {
      username: JSON.parse(localStorage.getItem('login')).login.payload.username
    },
    lastmodifiedBy: {
      username: JSON.parse(localStorage.getItem('login')).login.payload.username
    },
    category: {
      id: data.categoryId,
      name: data.categoryName
    },
    type: {
      id: data.typeId,
      name: data.typeName
    },
    bloodBank: {
      id: data.bloodId,
      name: data.bloodName
    },
    capacity: '',
    suppliesBlood: false,
    requiresBlood: false,
    collectsBlood: false,
    status: 'Active'
  };

  return getCollection(db, 'facility').insert(doc)
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.log('err : ', err);
      return false;
    });
};

/**
 * Updates Facility category
 *
 * @param {*} db    RxDB database
 * @param {*} data  facility category data
 * @returns Promise indicating if action succeed
 */
export const updateFacility = (db, data) => {
  const doc = getCollection(db, 'facility').findOne({
    selector: {
      id: data.id
    }
  });

  return doc
    .update({
      name: data.name,
      id: data.id,
      createdAt: data.createdAt,
      city: data.city,
      address: data.address,
      lastmodifiedAt: new Date().toISOString(),
      lastmodifiedBy: {
        username: data.username
      },
      category: {
        id: data.categoryId,
        name: data.categoryName
      },
      type: {
        id: data.typeId,
        name: data.typeName
      },
      bloodBank: {
        id: data.bloodId,
        name: data.bloodName
      },
      capacity: data.capacity,
      suppliesBlood: data.suppliesBlood,
      requiresBlood: data.requiresBlood,
      collectsBlood: data.collectsBlood,
      status: data.status
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.log('err : ', err);
      return false;
    });
};

/**
 * List All Pickup points (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listPickupPoints = async (db) => {
  return getCollection(db, 'facility')
    .find()
    .exec()
    .then((documents) => {
      return documents.filter((entry) => entry.bloodBank !== null).map((entry) => ({
        id: entry.stockUnit.id,
        name: entry.stockUnit.name,
        isActive: entry.isActive
      }));
    });
};

/**
 * List All Destinations (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listDestinations = async (db) => {
  return getCollection(db, 'facility')
    .find()
    .exec()
    .then((documents) => {
      return documents.filter((entry) => (entry.bloodBank !== null &&
        entry.acceptsDeliveries === true)).map((entry) => ({
        id: entry.bloodBank.id,
        name: entry.bloodBank.name,
        isActive: entry.isActive
      }));
    });
};
