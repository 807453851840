import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Col, Grid, Panel, Row } from 'rsuite';

import { ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';

const figures = {
  appointments: 30,
  donors: 3235,
  donations: 32,
  bloodProducts: 23,
  bloodRequests: 66,
  adverseEvents: 5
};

const DashboardFigures = () => {
  return (
    <div className='container'>
      <Panel bordered>
        <Grid fluid>
          <Row className='show-grid'>
            <Col xs={4}>
              <ViewLabel className='strong'>
                <FormattedMessage
                  id='dashboard.view.donors'
                  defaultMessage='No. of Donors'
                />
              </ViewLabel>
              <ViewLabelValue>
                <FormattedNumber value={figures.donors} />
              </ViewLabelValue>
            </Col>

            <Col xs={4}>
              <ViewLabel className='strong'>
                <FormattedMessage
                  id='dashboard.view.donations'
                  defaultMessage='Donations'
                />
              </ViewLabel>
              <ViewLabelValue>
                <FormattedNumber value={figures.donations} />
              </ViewLabelValue>
            </Col>

            <Col xs={4}>
              <ViewLabel className='strong'>
                <FormattedMessage
                  id='dashboard.view.blood-products'
                  defaultMessage='Blood Products'
                />
              </ViewLabel>
              <ViewLabelValue>
                <FormattedNumber value={figures.bloodProducts} />
              </ViewLabelValue>
            </Col>

            <Col xs={4}>
              <ViewLabel className='strong'>
                <FormattedMessage
                  id='dashboard.view.blood-requests'
                  defaultMessage='Blood Requests'
                />
              </ViewLabel>
              <ViewLabelValue>
                <FormattedNumber value={figures.bloodRequests} />
              </ViewLabelValue>
            </Col>

            <Col xs={4}>
              <ViewLabel className='strong'>
                <FormattedMessage
                  id='dashboard.view.adverse-events'
                  defaultMessage='Adverse Events'
                />
              </ViewLabel>
              <ViewLabelValue>
                <FormattedNumber value={figures.adverseEvents} />
              </ViewLabelValue>
            </Col>

            <Col xs={4}>
              <ViewLabel className='strong'>
                <FormattedMessage
                  id='dashboard.view.appointments'
                  defaultMessage='Appointments'
                />
              </ViewLabel>
              <ViewLabelValue>
                <FormattedNumber value={figures.appointments} />
              </ViewLabelValue>
            </Col>
          </Row>
        </Grid>
      </Panel>
    </div>
  );
};

export default DashboardFigures;
