import { getFullName, getPersonName } from '../../../utils/person';
import { auditFields, operations, types } from '../common';

/**
 * Entity schema
 */
const schema = {
  version: 0,

  title: 'Patient',
  description: 'Patient who requires a transfusion',

  primaryKey: 'id',
  type: 'object',
  properties: {
    id: types.ID,
    icode: types.STRING_INDEX,

    // patient specific fields

    patientCode: types.STRING_INDEX,
    bloodType: types.STRING_INDEX,
    status: types.STRING_INDEX, // admittance
    hospital: types.OBJECT,

    // person fields

    fullName: types.STRING_INDEX,
    personName: types.STRING_INDEX,
    title: types.STRING,
    firstName: types.STRING,
    middleName: types.STRING,
    lastName: types.STRING,
    gender: types.STRING,
    dateOfBirth: types.DATE_INDEX,
    details: types.STRING,

    ethnicGroup: types.OBJECT,
    languages: types.ARRAY,

    email1: types.EMAIL,
    email1Type: types.STRING,

    email2: types.EMAIL,
    email2Type: types.STRING,

    phone1Type: types.STRING,
    phone1CountryPrefix: types.STRING,
    phone1Number: types.STRING,

    phone2Type: types.STRING,
    phone2CountryPrefix: types.STRING,
    phone2Number: types.STRING,

    documentId1Type: types.STRING,
    documentId1Number: types.STRING,
    documentId1IssuedBy: types.STRING,
    documentId1ExpireDate: types.DATE,

    documentId2Type: types.STRING,
    documentId2Number: types.STRING,
    documentId2IssuedBy: types.STRING,
    documentId2ExpireDate: types.DATE,

    address1: types.STRING,
    address1Type: types.STRING,
    address1PostalCode: types.STRING,
    address1City: types.STRING,

    address2: types.STRING,
    address2Type: types.STRING,
    address2City: types.STRING,
    address2PostalCode: types.STRING,

    notificationConsent: types.BOOLEAN,
    notificationMethods: types.STRINGS,

    ...auditFields
  },

  required: ['id', 'firstName', 'lastName', 'gender', 'dateOfBirth'],
  indexes: [
    ['patientCode'],
    ['fullName'],
    ['personName']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * Also update full and person names
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: function (data) {
    data.personName = getPersonName(data);
    data.fullName = getFullName(data);
    return operations.add(this, data);
  },

  /**
   * Updates instance
   *
   * Also update full and person names
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    data.personName = getPersonName(data);
    data.fullName = getFullName(data);
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
