import { REPLICATION_SIZE } from '../../../utils/constants';
import { auditFields, buildReplication } from '../utils';

/**
 * Pull builder for vehicle data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listVehicles(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        plateNumber
        modelMake
        isFunctional
        isAvailable
        details

        type { id name }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Synchronize vehicle data with GraphQL endpoint
 */
export const syncVehicle = (...args) => {
  return buildReplication(
    ...args,
    'vehicle',
    { pullBuilder, pullQuery: 'listVehicles' }
  );
};
