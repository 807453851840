import { getNewUUID } from '../../../utils';
import { getCollection } from '..';
import { auditFields, operations, types } from '../common';

/**
 * Entity schema
 */
const schema = {
  version: 5,

  title: 'Collection task',
  description: 'Blood donations collection task',

  primaryKey: 'id',
  type: 'object',
  properties: {
    id: types.ID,
    icode: types.STRING_INDEX,

    dueTime: types.DATE_TIME_INDEX,
    priority: types.STRING_INDEX, // category
    status: types.STRING_INDEX, // activity
    details: types.STRING,

    pickupPoint: types.OBJECT,
    destination: types.OBJECT,
    vehicle: types.OBJECT,
    driver: types.OBJECT,

    consumables: types.ARRAY,
    equipments: types.ARRAY,
    healthCareWorkers: types.ARRAY,

    donations: types.ARRAY,

    // Audit fields
    ...auditFields
  },

  required: ['id', 'dueTime', 'priority', 'status', 'pickupPoint'],
  indexes: [
    ['dueTime'],
    ['priority'],
    ['status']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: async function (data, db) {
    // The linked donations do not have assigned the new collection yet
    // therefore that still appear as available in the notification screen
    // this is the workaround to fix that point

    const id = getNewUUID(); // get here the new ID
    const done = operations.add(this, { ...data, id });
    if (done) {
      // get donations collection
      const dc = getCollection(db, 'donation');
      // set the ID to all the linked donations
      await Promise.all(data.donations.map(async ({ id: donationId }) => {
        await operations.edit(dc, { id: donationId, collectionTask: { id } });
        return true;
      }));
    }
    return done;
  },

  /**
   * Updates instance
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
