import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for request data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listRequests(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        dueTime
        priority
        status
        quantity
        details

        bloodType
        bloodProductType { id name shelfLife shelfLifeUnit }
        bloodBank { id name address stockUnit { id name address } }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for request data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertRequest(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dueTime')}
          ${getMutationStringValue(doc, 'priority')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationNumberValue(doc, 'quantity')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationStringValue(doc, 'bloodType')}
          ${getMutationNestedValue(doc, 'bloodProductType')}
          ${getMutationNestedValue(doc, 'bloodBank')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize request data with GraphQL endpoint
 */
export const syncRequest = (...args) => {
  return buildReplication(
    ...args,
    'request',
    { pullBuilder, pullQuery: 'listRequests' },
    { pushBuilder }
  );
};
