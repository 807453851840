import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationArrayNestedValue,
  getMutationBooleanValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringsValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for patient data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listPatients(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        patientCode
        bloodType
        status
        hospital {
          id name address
          bloodBank { id name address stockUnit { id name address } }
        }

        fullName
        personName
        title
        firstName
        middleName
        lastName
        gender
        dateOfBirth
        details

        ethnicGroup { id name }
        languages { id name }

        email1
        email1Type

        email2
        email2Type

        phone1Type
        phone1CountryPrefix
        phone1Number

        phone2Type
        phone2CountryPrefix
        phone2Number

        documentId1Type
        documentId1Number
        documentId1IssuedBy
        documentId1ExpireDate

        documentId2Type
        documentId2Number
        documentId2IssuedBy
        documentId2ExpireDate

        address1
        address1Type
        address1PostalCode
        address1City

        address2
        address2Type
        address2City
        address2PostalCode

        notificationConsent
        notificationMethods

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for patient data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertPatient(
        input: {
          ${getMutationStringValue(doc, 'id')}

          ${getMutationStringValue(doc, 'bloodType')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationNestedValue(doc, 'hospital')}

          ${getMutationStringValue(doc, 'title')}
          ${getMutationStringValue(doc, 'firstName')}
          ${getMutationStringValue(doc, 'middleName')}
          ${getMutationStringValue(doc, 'lastName')}
          ${getMutationStringValue(doc, 'gender')}
          ${getMutationStringValue(doc, 'dateOfBirth')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'ethnicGroup')}
          ${getMutationArrayNestedValue(doc, 'languageNames', 'languages', 'name')}


          ${getMutationStringValue(doc, 'email1')}
          ${getMutationStringValue(doc, 'email1Type')}

          ${getMutationStringValue(doc, 'email2')}
          ${getMutationStringValue(doc, 'email2Type')}

          ${getMutationStringValue(doc, 'phone1Type')}
          ${getMutationStringValue(doc, 'phone1CountryPrefix')}
          ${getMutationStringValue(doc, 'phone1Number')}

          ${getMutationStringValue(doc, 'phone2Type')}
          ${getMutationStringValue(doc, 'phone2CountryPrefix')}
          ${getMutationStringValue(doc, 'phone2Number')}

          ${getMutationStringValue(doc, 'documentId1Type')}
          ${getMutationStringValue(doc, 'documentId1Number')}
          ${getMutationStringValue(doc, 'documentId1IssuedBy')}
          ${getMutationStringValue(doc, 'documentId1ExpireDate')}

          ${getMutationStringValue(doc, 'documentId2Type')}
          ${getMutationStringValue(doc, 'documentId2Number')}
          ${getMutationStringValue(doc, 'documentId2IssuedBy')}
          ${getMutationStringValue(doc, 'documentId2ExpireDate')}

          ${getMutationStringValue(doc, 'address1')}
          ${getMutationStringValue(doc, 'address1Type')}
          ${getMutationStringValue(doc, 'address1PostalCode')}
          ${getMutationStringValue(doc, 'address1City')}

          ${getMutationStringValue(doc, 'address2')}
          ${getMutationStringValue(doc, 'address2Type')}
          ${getMutationStringValue(doc, 'address2City')}
          ${getMutationStringValue(doc, 'address2PostalCode')}

          ${getMutationBooleanValue(doc, 'notificationConsent')}
          ${getMutationStringsValue(doc, 'notificationMethods')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize patient data with GraphQL endpoint
 */
export const syncPatient = (...args) => {
  return buildReplication(
    ...args,
    'patient',
    { pullBuilder, pullQuery: 'listPatients' },
    { pushBuilder }
  );
};
