import './dash.css';

import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Col, Dropdown, FlexboxGrid, Panel } from 'rsuite';

import { MarginDiv } from '../../containers/styled/layout';
import { ViewLabel, ViewLabelValue } from '../../containers/styled/typography';
import ChartOneSectionView from './sections/chartOneSection';
import ChartThreeSectionView from './sections/chartThreeSection';
import ChartTwoSectionView from './sections/chartTwoSection';
import DashboardFigures from './sections/figures';

const Dashboard = () => {
  const fakeData = {
    dates: ['2022-01-01', '2022-12-31'],
    location: 'Johannesburg'
  };

  return (
    <MarginDiv>
      <FlexboxGrid justify='space-between' style={{ padding: '5px 0 15px' }}>
        <FlexboxGrid.Item as={Col} md={12}>
          <ViewLabel className='strong'>
            <FormattedMessage
              id='dashboard.view.heading'
              defaultMessage='Dashboard'
            />
          </ViewLabel>

          <ViewLabelValue>
            <FormattedMessage
              id='dashboard.view.subheading'
              defaultMessage='Track, Manage and Get a birds eye view of all activities'
            />
          </ViewLabelValue>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} md={12}>
          <Panel bordered>
            <Dropdown
              title={
                <>
                  <FormattedDate
                    value={fakeData.dates[0]}
                    year='numeric'
                    month='short'
                    day='numeric'
                  />
                  {' - '}
                  <FormattedDate
                    value={fakeData.dates[1]}
                    year='numeric'
                    month='short'
                    day='numeric'
                  />
                </>
              }
              size='sm'
            />
            <Dropdown title={fakeData.location} size='sm' />
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      <DashboardFigures />

      <ChartOneSectionView />

      <ChartTwoSectionView />

      <ChartThreeSectionView />
    </MarginDiv>
  );
};

export default Dashboard;
