import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { bloodTypeMsg, severityMsg, statusMsg, translations } from '../../../translations/en/common';
import { parseDate, parseTime } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'requests.title',
    defaultMessage: 'Requests'
  },
  count: {
    id: 'requests.count.label',
    defaultMessage: 'Request(s)'
  },
  searchPlaceHolder: {
    id: 'requests.search.placeholder',
    defaultMessage: 'Search Requests'
  },
  bloodProductType: {
    id: 'requests.blood.product.type',
    defaultMessage: 'Blood Product Type'
  },
  quantity: {
    id: 'requests.quantity',
    defaultMessage: 'Quantity'
  },
  requestingFacility: {
    id: 'requests.requesting.facility',
    defaultMessage: 'Requesting Facility'
  },
  dateTime: {
    id: 'requests.datetime',
    defaultMessage: 'Date & Time'
  },
  status: {
    id: 'requests.status',
    defaultMessage: 'Status'
  }
});

/**
 * Request screen
 *
 * Request to display data table showing requests.
 *
 * @component
 * @example
 * return (
 *    <Requests />
 * );
 *
 */
const Requests = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    bloodProductType: '',
    quantity: '',
    priority: ''
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'request')
      .find()
      .where({ isActive: true })
      .sort({ dueTime: 1 })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          name: entry.bloodProductType.name
            ? entry.bloodProductType.name
            : formatMessage(translations.notApplicable),
          quantity: entry.quantity
            ? entry.quantity
            : formatMessage(translations.notApplicable),
          dateTime: entry.dueTime
            ? parseDate(entry.dateTime) + ' : ' + parseTime(entry.dateTime)
            : formatMessage(translations.notApplicable),
          requestingFacility: entry.bloodBank.name
            ? entry.bloodBank.name
            : formatMessage(translations.notApplicable),
          status: entry.status
            ? formatMessage(statusMsg[entry.status.toLowerCase()])
            : formatMessage(translations.notApplicable),
          bloodType: entry.bloodType
            ? formatMessage(bloodTypeMsg[entry.bloodType])
            : formatMessage(translations.notApplicable),
          priority: entry.priority
            ? formatMessage(severityMsg[entry.priority])
            : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'request']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.bloodProductType, flex: 2 },
          { dataKey: 'quantity', label: messages.quantity },
          {
            dataKey: 'requestingFacility',
            label: messages.requestingFacility,
            flex: 2
          },
          { dataKey: 'dateTime', label: messages.dateTime, flex: 2 },
          { dataKey: 'status', label: messages.status }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
        isStatus
      />
    </MarginDiv>
  );
};

export default Requests;
