import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import {
  H5,
  ViewLabel,
  ViewLabelValue
} from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Blood-test View screen
 *
 * Blood-test view to display each test in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns Blood-test view modal.
 *
 */
const BloodTestView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='bloodTest.details.title'
        defaultMessage='Test Result Information'
      />
    }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.donor.code'
                defaultMessage='Donor Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.facility'
                defaultMessage='Facility'
              />
            </ViewLabel>
            <ViewLabelValue>{item.facility}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='bloodTest.view.details.testDetails'
            defaultMessage='Test Details'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.testType'
                defaultMessage='Test Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.testType}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.result'
                defaultMessage='Result'
              />
            </ViewLabel>
            <ViewLabelValue>{item.result}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.status'
                defaultMessage='Status'
              />
            </ViewLabel>
            <ViewLabelValue>{item.status}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='bloodTest.view.details.healthWorkerInfo'
            defaultMessage='Health Worker Information'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={24}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.healthCareWorkers'
                defaultMessage='Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.healthCareWorkers}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='bloodTest.view.details.equipmentConsumables'
            defaultMessage='Equipment & Consumables'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.equipments'
                defaultMessage='Equipments'
              />
            </ViewLabel>
            {item.equipments !== ''
              ? (
                  item.equipments.map((data, index) => {
                    return <ViewLabelValue style={{ lineHeight: '10px' }} key={index}>{data.name}</ViewLabelValue>;
                  })
                )
              : (
                <ViewLabelValue>
                  <FormattedMessage
                    id='bloodTest.view.label.equipments.notApplicable'
                    defaultMessage='N/A'
                  />
                </ViewLabelValue>
                )}
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodTest.view.label.consumables'
                defaultMessage='Consumables'
              />
            </ViewLabel>
            {item.consumables !== ''
              ? (
                  item.consumables.map((data, index) => {
                    return <ViewLabelValue style={{ lineHeight: '10px' }} key={index}>{data.name}</ViewLabelValue>;
                  })
                )
              : (
                <ViewLabelValue>
                  <FormattedMessage
                    id='bloodTest.view.label.consumables.notApplicable'
                    defaultMessage='N/A'
                  />
                </ViewLabelValue>
                )}
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='bloodTest.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default BloodTestView;
