/**
 * Synchronization live interval (in milliseconds)
 */
export const REPLICATION_INTERVAL = 1000 * 60; // 1 minute

/**
 * Number of items to synchronize
 */
export const REPLICATION_SIZE = 10;

/**
 * Interval to refresh JWT (in milliseconds)
 */
export const REFRESH_JWT_INTERVAL = 1000 * 60 * 10; // 10 minutes

/**
 * Interval to log out automatically the user (in milliseconds)
 */
export const LOGOUT_INTERVAL = 1000 * 60 * 60 * 72; // 72 hours
