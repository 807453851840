import { defineMessage } from 'react-intl';

export const translations = defineMessage({
  boolean: {
    true: {
      id: 'common.boolean.true',
      defaultMessage: 'Yes'
    },
    false: {
      id: 'common.boolean.false',
      defaultMessage: 'No'
    }
  },

  notApplicable: {
    id: 'common.na',
    defaultMessage: 'N/A'
  }
});

export const statusMsg = defineMessage({
  scheduled: {
    id: 'common.status.scheduled',
    defaultMessage: 'Scheduled'
  },
  active: {
    id: 'common.status.active',
    defaultMessage: 'Active'
  },
  upcoming: {
    id: 'common.status.upcoming',
    defaultMessage: 'Upcoming'
  },
  ongoing: {
    id: 'common.status.ongoing',
    defaultMessage: 'Ongoing'
  },
  'in-progress': {
    id: 'common.status.inprogress',
    defaultMessage: 'In Progress'
  },
  completed: {
    id: 'common.status.completed',
    defaultMessage: 'Completed'
  },
  cancelled: {
    id: 'common.status.cancelled',
    defaultMessage: 'Cancelled'
  },
  'on-hold': {
    id: 'common.status.onhold',
    defaultMessage: 'On Hold'
  },
  admitted: {
    id: 'common.status.admitted',
    defaultMessage: 'Admitted'
  },
  other: {
    id: 'common.status.other',
    defaultMessage: 'Other'
  },
  discharged: {
    id: 'common.status.discharged',
    defaultMessage: 'Discharged'
  },
  discarded: {
    id: 'common.status.discarded',
    defaultMessage: 'Discarded'
  },
  'in-transit': {
    id: 'common.status.intransit',
    defaultMessage: 'In Transit'
  },
  tested: {
    id: 'common.status.tested',
    defaultMessage: 'Tested'
  },
  picked: {
    id: 'common.status.picked',
    defaultMessage: 'Picked'
  },
  init: {
    id: 'common.status.init',
    defaultMessage: 'Init'
  },
  collected: {
    id: 'common.status.collected',
    defaultMessage: 'Collected'
  },
  testing: {
    id: 'common.status.testing',
    defaultMessage: 'Testing'
  },
  accepted: {
    id: 'common.status.accepted',
    defaultMessage: 'Accepted'
  }
});

export const genderMsg = defineMessage({
  F: {
    id: 'common.gender.female',
    defaultMessage: 'Female'
  },
  M: {
    id: 'common.gender.male',
    defaultMessage: 'Male'
  }
});

export const bloodTypeMsg = defineMessage({
  'A-': {
    id: 'common.blood.type.a.negative',
    defaultMessage: 'A-'
  },
  'A+': {
    id: 'common.blood.type.a.positive',
    defaultMessage: 'A+'
  },
  'AB-': {
    id: 'common.blood.type.ab.negative',
    defaultMessage: 'AB-'
  },
  'AB+': {
    id: 'common.blood.type.ab.positive',
    defaultMessage: 'AB+'
  },
  'B-': {
    id: 'common.blood.type.b.negative',
    defaultMessage: 'B-'
  },
  'B+': {
    id: 'common.blood.type.b.positive',
    defaultMessage: 'B+'
  },
  'O-': {
    id: 'common.blood.type.o.negative',
    defaultMessage: 'O-'
  },
  'O+': {
    id: 'common.blood.type.o.positive',
    defaultMessage: 'O+'
  }
});

export const severityMsg = defineMessage({
  low: {
    id: 'common.severity.low',
    defaultMessage: 'Low'
  },
  high: {
    id: 'common.severity.high',
    defaultMessage: 'High'
  },
  medium: {
    id: 'common.severity.medium',
    defaultMessage: 'Medium'
  }
});

export const testTypeMsg = defineMessage({
  'blood-type-test': {
    id: 'common.testType.bloodtypetest',
    defaultMessage: 'Blood type test'
  },
  'donation-test': {
    id: 'common.testType.donationtest',
    defaultMessage: 'Donation test'
  },
  'in-depth-test': {
    id: 'common.testType.indepthtest',
    defaultMessage: 'In depth test'
  },
  'initial-test': {
    id: 'common.testType.initialtest',
    defaultMessage: 'Initial test'
  },
  'processing-test': {
    id: 'common.testType.processingtest',
    defaultMessage: 'Processing test'
  }
});

export const resultMsg = defineMessage({
  pass: {
    id: 'common.result.pass',
    defaultMessage: 'Pass'
  },
  fail: {
    id: 'common.result.fail',
    defaultMessage: 'Fail'
  }
});
