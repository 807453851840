import { FormattedDate, FormattedMessage } from 'react-intl';
import { Col, Grid, Row } from 'rsuite';

import { ViewMarginDiv } from '../../containers/styled/layout';
import { ViewLabel, ViewLabelValue } from '../../containers/styled/typography';

const FormatDate = (value) => {
  if (!value) return '---';

  return (
    <FormattedDate
      value={value.value}
      dateStyle='long'
      timeStyle='medium'
    />
  );
};

/**
 * Audit Fields component
 *
 * Audit fields view to display audit information for an item.
 *
 * @component
 * @param {object} item       audit data
 * @example
 * return (
 *    <AuditFieldsView item={item} />
 * )
 *
 */
const AuditFieldsView = ({ item }) => (
  <>
    <Grid fluid>
      <Row>
        <Col xs={12}>
          <ViewLabel>
            <FormattedMessage
              id='audit.view.label.modified'
              defaultMessage='Last modified at'
            />
          </ViewLabel>
          <ViewLabelValue><FormatDate value={item.lastmodifiedAt} /></ViewLabelValue>
        </Col>
        <Col xs={12}>
          <ViewLabel>
            <FormattedMessage
              id='audit.view.label.synched'
              defaultMessage='Last synchronization at'
            />
          </ViewLabel>
          <ViewLabelValue><FormatDate value={item.lastSynchedAt} /></ViewLabelValue>
        </Col>
      </Row>
      <ViewMarginDiv />
      <Row>
        <Col xs={12}>
          <ViewLabel>
            <FormattedMessage
              id='audit.view.label.createdBy'
              defaultMessage='Created By'
            />
          </ViewLabel>
          <ViewLabelValue><FormatDate value={item.createdBy} /></ViewLabelValue>
        </Col>
        <Col xs={12}>
          <ViewLabel>
            <FormattedMessage
              id='audit.view.label.createdAt'
              defaultMessage='Created Date'
            />
          </ViewLabel>
          <ViewLabelValue><FormatDate value={item.createdAt} /></ViewLabelValue>
        </Col>
      </Row>
    </Grid>
  </>
);

export default AuditFieldsView;
