import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../containers/styled/layout';
import { useDatabase } from '../../context/database';
import { deleteUserRole } from '../../controllers/users/UserRoleController';
import { getCollection } from '../../rxdb/collections';
import { permissions } from '../../utils/auth';
import DataEntity from '../generic/DataEntity';
import FormComponent from './subcomponents/UserRoleForm';
import ViewComponent from './subcomponents/UserRoleView';

const messages = defineMessages({
  title: {
    id: 'user.role.title',
    defaultMessage: 'Roles'
  },
  count: {
    id: 'user.role.count.label',
    defaultMessage: 'Role(s)'
  },
  searchPlaceHolder: {
    id: 'user.role.search.placeholder',
    defaultMessage: 'Search roles'
  },
  name: {
    id: 'user.role.name',
    defaultMessage: 'Role name'
  },
  details: {
    id: 'user.role.details',
    defaultMessage: 'Details'
  },
  add: {
    id: 'user.role.add.button',
    defaultMessage: 'Create user role'
  }
});

const ADD = permissions.role.add;
const EDIT = permissions.role.edit;
const DELETE = permissions.role.del;

/**
 * User Role screen
 *
 * User role to display data table showing list of roles.
 *
 * @component
 * @example
 * return (
 *    <UserRole />
 * );
 *
 */
const UserRole = () => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'role')
      .find()
      .where({ isActive: true })
      .sort({ name: 1 })
      .$
      .subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          name: entry.name,
          details: entry.details,
          lastmodifiedAt: entry.lastmodifiedAt,
          lastSynchedAt: entry.lastSynchedAt
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  return (
    <MarginDiv data-testid='user-role'>
      <DataEntity
        crumbs={['role']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'details', label: messages.details, flex: 2 }
        ]}
        addPerm={ADD}
        editPerm={EDIT}
        FormComponent={FormComponent}
        deletePerm={DELETE}
        onDelete={deleteUserRole}
        ViewComponent={ViewComponent}
        messages={messages}
      />

    </MarginDiv>
  );
};

export default UserRole;
