import AppRoutes from './components/routing/AppRoutes';

/**
 * Component that renders frontend Biskit application
 *
 * @component
 * @example
 * const root = createRoot(document.getElementById('root'));
 *
 * root.render(
 *   <React.StrictMode>
 *     <App />
 *   </React.StrictMode>
 * );
 */
const App = () => (
  <div data-testid='biskit-app'>
    <AppRoutes />
  </div>
);

export default App;
