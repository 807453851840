import { auditFields, emptyMigration, operations, types } from '../common';

/**
 * Entity schema
*/
const schema = {
  version: 1,

  title: 'User',
  description: 'User involved in any step of the blood donation workflow',

  primaryKey: 'username',
  type: 'object',
  properties: {
    username: { ...types.ID, maxLength: 150 },
    name: types.STRING_INDEX,
    email: types.EMAIL,

    facility: types.OBJECT,
    role: types.OBJECT,

    isStaff: types.BOOLEAN,
    isSuperuser: types.BOOLEAN,

    isDriver: types.BOOLEAN,
    isHcw: types.BOOLEAN,

    phone1Type: types.STRING,
    phone1CountryPrefix: types.STRING,
    phone1Number: types.STRING,

    phone2Type: types.STRING,
    phone2CountryPrefix: types.STRING,
    phone2Number: types.STRING,

    ...auditFields
  },

  required: ['username'],
  indexes: [
    ['username'],
    ['name']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {
  // primaryKey has maxLength property
  1: emptyMigration
};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * Also updates full and person names
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: function (data) {
    return operations.add(this, data);
  },

  /**
   * Updates instance
   *
   * Also updates full and person names
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
