import { v4 as uuid4 } from 'uuid';

import { getCollection } from '../../rxdb/collections';

/**
 * List All Facility Type
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const getFacilityTypes = async (db) => {
  return getCollection(db, 'facilityType').find()
    .exec()
    .then((documents) => {
      return documents.map((entry) => ({
        id: entry.id,
        name: entry.name,
        isActive: entry.isActive
      }));
    });
};

/**
 * Creates Facility Type
 *
 * @param {*} db    RxDB database
 * @param {*} data  facility type data
 * @returns Promise indicating if action succeed
 */
export const createFacilityType = (db, data) => {
  const doc = {

    id: uuid4(),
    name: data.name,
    details: data.details,
    isActive: true,
    role: data.role,

    // Audit fields
    lastmodifiedAt: new Date().toISOString()
  };

  return getCollection(db, 'facilityType')
    .insert(doc)
    .then(() => {
      return true;
    }).catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Updates Facility Type
 *
 * @param {*} db    RxDB database
 * @param {*} data  facility type data
 * @returns Promise indicating if action succeed
 */
export const updateFacilityType = (db, data) => {
  const { id, ...updated } = data;
  const doc = getCollection(db, 'facilityType').findOne({ selector: { id } });

  return doc
    .update({
      $set: {
        ...updated,
        // Audit fields
        lastmodifiedAt: new Date().toISOString()
      }
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Deletes Facility Type
 *
 * @param {*} db    RxDB database
 * @param {*} data  facility type data
 * @returns Promise indicating if action succeed
 */
export const deleteFacilityType = (db, data) => {
  return updateFacilityType(db, {
    id: data.id,
    // workaround to skip name uniqueness constraint
    name: `${data.name} :: ${data.id} :: ${new Date().toISOString()}`,
    isActive: false
  });
};
