import { FormattedMessage } from 'react-intl';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';

import { OpenModal } from '../../../containers/styled/alerts';
import { MarginDiv, MarginSpan, WhiteDiv } from '../../../containers/styled/layout';
import { Label } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * User Role View screen
 *
 * User role view to display role in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @example
 * return (
 *    <UserRoleView />
 * );
 *
 */
const UserRoleView = ({ item, handleClose }) => (
  <OpenModal
    data-testid='view-user-role'
    onClose={handleClose}
    title={
      <FormattedMessage
        id='user.role.details.title'
        defaultMessage='Role details'
      />
    }
  >
    <Modal.Body>
      <FlexboxGrid justify='space-around' align='top'>
        <FlexboxGrid.Item colspan={10}>
          <Label>
            <FormattedMessage
              id='user.role.view.label.id'
              defaultMessage='Role ID'
            />
          </Label>
          <MarginDiv />
          <MarginSpan>{item.id}</MarginSpan>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={10}>
          <Label>
            <FormattedMessage
              id='users.role.view.label.name'
              defaultMessage='Role name'
            />
          </Label>
          <MarginDiv />
          <MarginSpan>{item.name}</MarginSpan>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={24}>
          <WhiteDiv />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item colspan={22}>
          <Label>
            <FormattedMessage
              id='users.role.view.label.details'
              defaultMessage='Details'
            />
          </Label>
          <MarginDiv />
          <MarginSpan>{item.details}</MarginSpan>
        </FlexboxGrid.Item>

        <AuditFieldsView item={item} />
      </FlexboxGrid>
    </Modal.Body>
  </OpenModal>
);

export default UserRoleView;
