import React from 'react';

import LineChart from '../generics/lineChart';

const distOfTestByTypeOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
      labels: { usePointStyle: true }
    },
    title: {
      display: true,
      text: 'Distribution of Tests by Type'
    }
  },
  elements: { line: { tension: 0.4 } },
  scales: {
    x: { grid: { display: false } },
    y: { grid: { display: false } }
  }
};

const distOfTestByTypeData = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  datasets: [
    {
      label: 'Donation test',
      data: [10, 30, 18, 34, 28, 38, 12, 18, 21, 4, 0, 0],
      borderColor: '#B02C17',
      backgroundColor: '#B02C17'
    },
    {
      label: 'Blood Type Test',
      data: [22, 25, 34, 11, 8, 14, 12, 12, 28, 14, 10, 3],
      borderColor: '#B3B3B3',
      backgroundColor: '#B3B3B3'
    },
    {
      label: 'Initial Test',
      data: [16, 20, 28, 44, 38, 18, 22, 38, 11, 14, 20, 10],
      borderColor: '#D97706',
      backgroundColor: '#D97706'
    }
  ]
};

const ChartThreeSectionView = () => {
  return (
    <div className='flexed-chart-three-section'>
      <div style={{ height: '100%', width: '90%', margin: '0 auto' }}>
        <LineChart
          options={distOfTestByTypeOptions}
          data={distOfTestByTypeData}
        />
      </div>
    </div>
  );
};

export default ChartThreeSectionView;
