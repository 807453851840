import React from 'react';

import BarChart from '../generics/barChart';

// Distribution of Donors by Blood Group
const distByBloodGrpData = {
  labels: ['O-', 'O+', 'A-', 'AB-', 'AB+'],
  datasets: [
    {
      data: [40, 25, 18, 34, 28],
      borderColor: '#FBE7E3',
      backgroundColor: '#FBE7E3',
      fill: true
    }
  ]
};

const distByBloodGrpOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      fontSize: 15,
      text: 'Distribution of Donors by Blood Group'
    },
    legend: {
      display: false,
      position: 'top'
    }
  },
  scales: {
    x: { grid: { display: false } },
    y: { grid: { display: false } }
  }
};

// Distribution of Donors by Age
const distByAgeData = {
  labels: ['22', '25', '26', '28', '34'],
  datasets: [
    {
      data: [35, 25, 18, 34, 28],
      borderColor: '#FBE7E3',
      backgroundColor: '#FBE7E3',
      fill: true
    }
  ]
};

const distByAgeOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: 'Distribution of Donors by Age',
      fontSize: 15
    },
    legend: {
      display: false,
      position: 'top'
    }
  },
  scales: {
    x: { grid: { display: false } },
    y: { grid: { display: false } }
  }
};

// Distribution of Tests by Status
const distOfBloodByStatus = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY'],
  datasets: [
    {
      data: [6, 8, 10, 6, 12],

      label: 'Fail',
      borderColor: '#B02C17',
      backgroundColor: '#B02C17',
      fill: true
    },

    {
      data: [23, 16, 14, 25, 18],
      label: 'Pass',
      borderColor: '#FBE7E3',
      backgroundColor: '#FBE7E3',
      fill: true
    }
  ]
};

const distOfBloodByStatusOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      fontSize: 15,
      text: 'Distribution of Tests by Status'
    },
    legend: {
      display: true,
      position: 'top',
      labels: { usePointStyle: true }
    }
  },
  scales: {
    x: {
      stacked: true,
      grid: { display: false }
    },
    y: {
      stacked: true,
      grid: { display: false }
    }
  }
};

const ChartTwoSectionView = () => {
  return (
    <div className='flexed-chart-two-section'>
      <div className='chart-two-bar-container'>
        <div style={{ height: '100%', width: '90%', margin: 'auto' }}>
          <BarChart options={distByBloodGrpOptions} data={distByBloodGrpData} />
        </div>
      </div>

      <div className='chart-two-bar-container'>
        <div style={{ height: '100%', width: '90%', margin: 'auto' }}>
          <BarChart options={distByAgeOptions} data={distByAgeData} />
        </div>
      </div>

      <div className='chart-two-bar-container'>
        <div style={{ height: '100%', width: '90%', margin: 'auto' }}>
          <BarChart
            options={distOfBloodByStatusOptions}
            data={distOfBloodByStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default ChartTwoSectionView;
