import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import {
  H5,
  ViewLabel,
  ViewLabelValue
} from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Blood-donation View screen
 *
 * Blood-donation view to display each donation in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns Blood-donation view modal.
 *
 */
const BloodDonationView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='bloodDonation.details.title'
        defaultMessage='Blood Donation Details'
      />
    }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.donor.code'
                defaultMessage='Donor Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.donorCode}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.donor.name'
                defaultMessage='Donor Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='bloodDonation.view.details.bloodDonation'
            defaultMessage='Blood Donation Details'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.length'
                defaultMessage='Length (cm)'
              />
            </ViewLabel>
            <ViewLabelValue>{item.length}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.width'
                defaultMessage='Width (cm)'
              />
            </ViewLabel>
            <ViewLabelValue>{item.width}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.volume'
                defaultMessage='Volume (ml)'
              />
            </ViewLabel>
            <ViewLabelValue>{item.volume}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='bloodDonation.view.details.healthWorkerInfo'
            defaultMessage='Health Worker Information'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={24}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.healthCareWorkers'
                defaultMessage='Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.healthCareWorkers}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='bloodDonation.view.details.equipmentConsumables'
            defaultMessage='Equipment & Consumables'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.equipments'
                defaultMessage='Equipments'
              />
            </ViewLabel>
            {item.equipments !== 'N/A' && item.equipments !== ''
              ? (
                  item.equipments.map((data, index) => {
                    return <ViewLabelValue style={{ lineHeight: '10px' }} key={index}>{data.name}</ViewLabelValue>;
                  })
                )
              : (
                <ViewLabelValue>
                  <FormattedMessage
                    id='bloodDonation.view.label.equipments.notApplicable'
                    defaultMessage='N/A'
                  />
                </ViewLabelValue>
                )}
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='bloodDonation.view.label.consumables'
                defaultMessage='Consumables'
              />
            </ViewLabel>
            {item.consumables !== 'N/A' && item.consumables !== ''
              ? (
                  item.consumables.map((data, index) => {
                    return <ViewLabelValue style={{ lineHeight: '10px' }} key={index}>{data.name}</ViewLabelValue>;
                  })
                )
              : (
                <ViewLabelValue>
                  <FormattedMessage
                    id='bloodDonation.view.label.consumables.notApplicable'
                    defaultMessage='N/A'
                  />
                </ViewLabelValue>
                )}
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='bloodDonation.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default BloodDonationView;
