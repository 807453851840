import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import { H5, ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Delivery View screen
 *
 * Delivery view to display each cross delivery in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns delivery view modal.
 *
 */
const DeliveriesView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='deliveries.details.title'
        defaultMessage='Delivery Details'
      />
    }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='deliveries.view.label.bloodProductType'
                defaultMessage='Blood Product Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='deliveries.view.equipments'
                defaultMessage='Equipments'
              />
            </ViewLabel>
            <ViewLabelValue>{item.equipments}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='deliveries.view.label.quantity'
                defaultMessage='Quantity'
              />
            </ViewLabel>
            <ViewLabelValue>{item.quantity}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='deliveries.view.label.pickupPoint'
                defaultMessage='Pickup Point'
              />
            </ViewLabel>
            <ViewLabelValue>{item.pickupPoint}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='deliveries.view.label.destination'
                defaultMessage='Destination'
              />
            </ViewLabel>
            <ViewLabelValue>{item.destination}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='deliveries.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default DeliveriesView;
