import { FormattedMessage } from 'react-intl';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Modal from 'rsuite/Modal';
import Notification from 'rsuite/Notification';
import toaster from 'rsuite/toaster';
import styled from 'styled-components';

import { PrimaryButton, PrimaryHoloButton } from './buttons';
import { FaIcon } from './layout';
import { StyledDivider } from './styled';
import { Label } from './typography';

/**
 * Component that renders an Alert with a message
 *
 * @component
 * @param {string} message alert messages
 * @param {string} header  header mssage
 * @example
 * return (
 *   <Alert type='success' header='Action' message='Action executed successfully' />
 * );
 */
export const Alert = ({ type = 'success', message, header = '' }) => {
  return (
    <Notification type={type} header={header} closable>
      {message}
    </Notification>
  );
};

const Title = styled(Label)`
  line-height: ${(props) => props.theme.font.sizes.xl};
  font-size: ${(props) => props.theme.font.sizes.m};
  font-weight: ${(props) => props.theme.font.weights.bold};
  color: ${(props) => props.theme.colors.font_dark};
`;

const CloseButton = styled.button`
  color: ${(props) => props.theme.colors.close_button};
  background-color: transparent;
  float: right;
  margin-top: -1.52rem;
`;

const ModalDiv = styled(Modal)`
  .rs-modal-dialog .rs-modal-content {
    border-radius: 4px;
    padding: 14px 0;
  }

  .rs-modal-dialog .rs-modal-content .rs-modal-header,
  .rs-modal-dialog .rs-modal-content .rs-modal-body,
  .rs-modal-dialog .rs-modal-content .rs-modal-footer {
    margin: 0 64px;
  }

  .rs-modal-dialog .rs-modal-content .rs-modal-header-close {
    right: 60px;
    top: 27px;
  }

  .rs-modal-dialog .rs-modal-content .rs-modal-footer {
    padding-bottom: 40px;
  }

  .rs-modal-dialog .rs-modal-content .rs-modal-body {
    padding-top: 20px;
  }

  .rs-modal-dialog .rs-modal-body h4 {
    font-weight: ${(props) => props.theme.font.weights.bold};
    color: ${(props) => props.theme.colors.font_dark};
  }
`;

const modalButton = { padding: '0.7rem' };

const iconSpacing = { marginLeft: 10, marginRight: 5 };

/**
 * Component that renders a Modal (open)
 *
 * @component
 * @param {string} title      a title for a modal
 * @param onClose             function called after closing the alert
 * @param children            data displayed in the modal
 * @example
 * return (
 *   <OpenModal title='My title' onClose={() => { ... }}>
 *     ...
 *   </OpenModel>
 * );
 */

export const OpenModal = ({
  title,
  onClose,
  children,
  closeLabel = true,
  size
}) => (
  <ModalDiv size={size} open onClose={onClose}>
    <Modal.Header>
      <Modal.Title>
        <Title>{title}</Title>
      </Modal.Title>

      {closeLabel && (
        <CloseButton onClick={onClose}>
          <FormattedMessage id='modal.close' defaultMessage='Close' />
        </CloseButton>
      )}
    </Modal.Header>

    <StyledDivider />

    {children}
  </ModalDiv>
);

/**
 * Component that renders a Modal asking a YES/NO question.
 *
 * @component
 * @example
 * return (
 *   <ConfirmModal
 *     title='Close app'
 *     message='Are you sure?'
 *     onClose={() => { ... }}
 *     onConfirm={() => { ... }}
 *   />
 * );
 */
export const ConfirmModal = ({ onClose, onConfirm, title, message }) => {
  return (
    <OpenModal
      title={title}
      onClose={onClose}
      data-testid='confirm-model'
      closeLabel={false}
      size='xs'
    >
      <Modal.Body>
        <div style={{ padding: '0 50px' }}>{message}</div>
      </Modal.Body>

      <Modal.Footer>
        <FlexboxGrid justify='center' align='middle'>
          <FlexboxGrid.Item>
            <PrimaryHoloButton
              style={modalButton}
              onClick={onClose}
              data-testid='cancel-delete'
            >
              <FaIcon icon='times' />
              <span style={iconSpacing}>
                <FormattedMessage
                  id='modal.confirm.no'
                  defaultMessage='No, Cancel'
                />
              </span>
            </PrimaryHoloButton>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item>
            <PrimaryButton
              style={modalButton}
              onClick={onConfirm}
              data-testid='yes-delete'
            >
              <FaIcon icon='check' />
              <span style={iconSpacing}>
                <FormattedMessage
                  id='modal.confirm.yes'
                  defaultMessage='Yes, Delete'
                />
              </span>
            </PrimaryButton>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Modal.Footer>
    </OpenModal>
  );
};

/**
 * Component that renders a Filter Modal.
 *
 * @component
 * @example
 * return (
 *   <FilterModal
 *
 *   />
 * );
 */

export const FilterModal = ({ onClose, children, size }) => (
  <ModalDiv onClose={onClose} size={size} open>
    {children}
  </ModalDiv>
);

/**
 * Notify indicated message with an alert an hide it after the indicated timeout
 *
 * @param {string}  message   alert messages
 * @param {string}  header    alert header
 * @param {string}  type      alert type
 * @param {number}  timeout   in milliseconds
 */
export const notify = (
  message,
  header = '',
  type = 'success',
  timeout = 2000
) => {
  const msg = <Alert type={type} header={header} message={message} />;
  const key = toaster.push(msg, { placement: 'topCenter' });

  setTimeout(() => {
    toaster.remove(key);
  }, timeout);
};
