import { getCollection } from '../../rxdb/collections';

/**
 * List All Facilities (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listFacility = async (db) => {
  return getCollection(db, 'facility')
    .find()
    .exec()
    .then((documents) => {
      return documents.map((entry) => ({
        id: entry.id,
        name: entry.name,
        suppliesBlood: entry.suppliesBlood,
        requiresBlood: entry.requiresBlood,
        isActive: entry.isActive
      }));
    });
};

/**
 * List All Blood Product Types (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise indicating if action succeed
 */
export const listBloodProductTypes = async (db) => {
  return getCollection(db, 'bloodProductType')
    .find()
    .exec()
    .then((documents) => {
      return documents.map((entry) => ({
        id: entry.id,
        name: entry.name,
        isActive: entry.isActive
      }));
    });
};
