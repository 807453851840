import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import FlexboxGrid from 'rsuite/FlexboxGrid';

import AppLogo from '../../assets/images/app-logo.svg';
import { notify } from '../../containers/styled/alerts';
import { PrimaryButton } from '../../containers/styled/buttons';
import { TextField } from '../../containers/styled/inputs';
import { WhiteDiv } from '../../containers/styled/layout';
import { Label } from '../../containers/styled/typography';
import { useAuth } from '../../context/auth';
import { StyledBackgroundImg, StyledWelcome, StyledWelcomeCon, Wrapper } from './styled';

const messages = defineMessages({
  usernamePlaceholder: {
    id: 'signin.form.username.placeholder',
    defaultMessage: 'Enter Username'
  },
  usernameError: {
    id: 'signin.form.username.error.required',
    defaultMessage: 'Username field is required'
  },
  passwordPlaceholder: {
    id: 'signin.form.password.placeholder',
    defaultMessage: 'Enter Password'
  },
  passwordError: {
    id: 'signin.form.password.error.required',
    defaultMessage: 'Password field is required'
  },
  failed: {
    id: 'signin.failed.message',
    defaultMessage: 'Something went wrong'
  },
  failedHeader: {
    id: 'signin.failed.header',
    defaultMessage: 'Error'
  },
  credentialsError: {
    id: 'signin.form.credentials.error',
    defaultMessage: 'Please enter valid credentials'
  }
});

/**
 * Sign In screen
 *
 * Requests username and password and logs in against GraphQL endpoint.
 *
 * @component
 * @example
 * return (
 *    <SignIn />
 * );
 */
const SignIn = () => {
  const { formatMessage } = useIntl();

  const { login, logout } = useAuth();

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState(true);

  const handleSubmit = () => {
    if (!username) {
      notify(
        formatMessage(messages.usernameError),
        formatMessage(messages.failedHeader),
        'error'
      );
      return;
    }

    if (!password) {
      notify(
        formatMessage(messages.passwordError),
        formatMessage(messages.failedHeader),
        'error'
      );
      return;
    }

    login(username, password).catch((err) => {
      const msgId =
        err.message === 'credentials' ? 'credentialsError' : 'failed';
      notify(
        formatMessage(messages[msgId]),
        formatMessage(messages.failedHeader),
        'error'
      );
    });
  };

  React.useEffect(() => {
    logout();
  }, [logout]);

  // validating the empty fields
  React.useEffect(() => {
    setIsDisabled(true);
    if (username && password) {
      setIsDisabled(false);
    }
  }, [username, password]);

  return (
    <Wrapper data-testid='signin'>
      <FlexboxGrid justify='center' align='middle'>
        <FlexboxGrid.Item colspan={12}>
          <StyledWelcomeCon>
            <img src={AppLogo} height='80' alt='Biskit' />
            <StyledWelcome>
              <FormattedMessage
                id='signin.form.welcome.text'
                defaultMessage='Welcome back! Please enter your details'
              />
            </StyledWelcome>
          </StyledWelcomeCon>

          <WhiteDiv style={{ width: '50%', margin: 'auto' }}>
            <div>
              <Label>
                <FormattedMessage
                  id='signin.form.label.username'
                  defaultMessage='Username'
                />
              </Label>
              <TextField
                name='username'
                type='text'
                value={username}
                placeholder={formatMessage(messages.usernamePlaceholder)}
                onChange={setUsername}
              />
            </div>

            <div>
              <Label>
                <FormattedMessage
                  id='signin.form.label.password'
                  defaultMessage='Password'
                />
              </Label>
              <TextField
                name='password'
                type='password'
                value={password}
                placeholder={formatMessage(messages.passwordPlaceholder)}
                onChange={setPassword}
              />
            </div>

            <PrimaryButton
              disabled={isDisabled}
              style={{ width: '100%', marginTop: '25px' }}
              onClick={handleSubmit}
            >
              <FormattedMessage
                id='signin.form.login.button'
                defaultMessage='Login'
              />
            </PrimaryButton>
          </WhiteDiv>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
          <StyledBackgroundImg />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Wrapper>
  );
};

export default SignIn;
