/**
 * Styled Theme
 */
export const Theme = {
  colors: {
    primary: '#b02c17',
    primary_dark: '#941d0a',
    secondary: '#be5443',
    tertiary: '#b02c1724',

    font: '#4a4a4a',
    font_dark: '#010005',
    font_neutral: '#2b2727',

    white: '#fff',
    black: '#000',
    grey_granite: '#505050',

    grey: '#62707b',
    grey_dark: '#5a6872',
    grey_lid: '#a4b2bd',
    grey_light: '#e0e7ed',
    grey_background: '#f3f8fa',
    grey_border: '#d9d9d9',
    grey_divider: '#4a4a4a33',
    grey_alert_border: '#e6e6e6',
    grey_dim: '#737373',
    hover_color: '#fbfafa',

    green: '#74d2a3',
    orange: '#e0b36a',
    red: '#e27b8c',

    bg: '#f5f6fa',
    border: '#b02c17'
  },

  font: {
    family: "'Open Sans', helvetica, arial, sans-serif",
    size: '16px',
    sizes: {
      xxs: '0.65rem',
      xs: '0.75rem',
      s: '0.87rem',
      standard: '1rem',
      xm: '1.25rem',
      m: '1.5rem',
      l: '1.75rem',
      xl: '2rem',
      xxl: '3rem'
    },
    weights: {
      thin: 'lighter',
      regular: '400',
      bold: '600'
    }
  },

  lineHeight: '1.6rem',
  radius: {
    sizes: {
      standard: '2px',
      m: '5px',
      l: '14px',
      xl: '18px',
      xxl: '30px'
    }
  }
};
