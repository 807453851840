import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import { H5, ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Donor View screen
 *
 * Donor view to display each donor in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns donor view modal.
 *
 */
const DonorView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='donor.details.title'
        defaultMessage='Donor Information'
      />
      }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.code'
                defaultMessage='Donor Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.donorCode}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.firstName'
                defaultMessage='First Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.firstName}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.lastName'
                defaultMessage='Last Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.lastName}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.gender'
                defaultMessage='Gender'
              />
            </ViewLabel>
            <ViewLabelValue>{item.gender}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.dateOfBirth'
                defaultMessage='Date of Birth'
              />
            </ViewLabel>
            <ViewLabelValue>{item.dateOfBirth}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.ethnicGroup'
                defaultMessage='Ethnic Group'
              />
            </ViewLabel>
            <ViewLabelValue>{item.ethnicGroup}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.language'
                defaultMessage='Language'
              />
            </ViewLabel>
            <ViewLabelValue>{item.languages}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='donor.view.details.bloodInformation'
            defaultMessage='Blood Information'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.bloodType'
                defaultMessage='Donor Blood Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.bloodType}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.bloodQuality'
                defaultMessage='Blood Quality'
              />
            </ViewLabel>
            <ViewLabelValue>{item.bloodQuality}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.consentAgreement'
                defaultMessage='Consent Agreement'
              />
            </ViewLabel>
            <ViewLabelValue>{item.consentAgreement}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.notificationConsent'
                defaultMessage='Notification Consent'
              />
            </ViewLabel>
            <ViewLabelValue>{item.notificationConsent}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='donor.view.details.documents'
            defaultMessage='Documents'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentTypeOne'
                defaultMessage='Document Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentTypeOne}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentNumberOne'
                defaultMessage='ID Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentNumberOne}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentIssuedByOne'
                defaultMessage='Issued By'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentIssuedByOne}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentExpireDateOne'
                defaultMessage='Expiry Date'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentExpireDateOne}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentTypeTwo'
                defaultMessage='Document Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentTypeTwo}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentNumberTwo'
                defaultMessage='ID Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentNumberTwo}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentIssuedByTwo'
                defaultMessage='Issued By'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentIssuedByTwo}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.documentExpireDateTwo'
                defaultMessage='Expiry Date'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentExpireDateTwo}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='donor.view.details.contactInformation'
            defaultMessage='Contact Information'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.email'
                defaultMessage='Email Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.email}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.mobile'
                defaultMessage='Mobile Phone Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.mobile}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.address'
                defaultMessage='Home Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.city'
                defaultMessage='City'
              />
            </ViewLabel>
            <ViewLabelValue>{item.city}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.postalCode'
                defaultMessage='Postal Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.postalCode}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.workEmail'
                defaultMessage='Email Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.workEmail}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.workPhone'
                defaultMessage='Mobile Phone Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.workPhone}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.workAddress'
                defaultMessage='Home Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.workAddress}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.workCity'
                defaultMessage='City'
              />
            </ViewLabel>
            <ViewLabelValue>{item.workCity}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='donor.view.label.workPostalCode'
                defaultMessage='Postal Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.workPostalCode}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='donor.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default DonorView;
