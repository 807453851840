import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationBooleanValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for user data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listUsers(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        username
        firstName
        lastName
        name
        email

        facility { id name address }
        role { id name permissionKeys }

        isStaff
        isSuperuser

        isDriver
        isHcw

        phone1Type
        phone1CountryPrefix
        phone1Number

        phone2Type
        phone2CountryPrefix
        phone2Number

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for user data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertUser(
        input: {
          ${getMutationStringValue(doc, 'username')}
          ${getMutationStringValue(doc, 'firstName')}
          ${getMutationStringValue(doc, 'lastName')}
          ${getMutationStringValue(doc, 'email')}

          ${getMutationNestedValue(doc, 'facility')}
          ${getMutationNestedValue(doc, 'role')}

          ${getMutationBooleanValue(doc, 'isStaff')}
          ${getMutationBooleanValue(doc, 'isSuperuser')}

          ${getMutationBooleanValue(doc, 'isDriver')}
          ${getMutationBooleanValue(doc, 'isHcw')}

          ${getMutationStringValue(doc, 'phone1Type')}
          ${getMutationStringValue(doc, 'phone1CountryPrefix')}
          ${getMutationStringValue(doc, 'phone1Number')}

          ${getMutationStringValue(doc, 'phone2Type')}
          ${getMutationStringValue(doc, 'phone2CountryPrefix')}
          ${getMutationStringValue(doc, 'phone2Number')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize user data with GraphQL endpoint
 */
export const syncUser = (...args) => {
  return buildReplication(
    ...args,
    'user',
    { pullBuilder, pullQuery: 'listUsers' },
    { pushBuilder }
  );
};
