import React from 'react';
import { defineMessages } from 'react-intl';

import { MarginDiv } from '../../containers/styled/layout';
import { useDatabase } from '../../context/database';
import { deleteFacilityType } from '../../controllers/facilities/FacilityTypeController';
import { getCollection } from '../../rxdb/collections';
import { permissions } from '../../utils/auth';
import DataEntity from '../generic/DataEntity';
import FormComponent from './subcomponents/FacilityTypeForm';
import ViewComponent from './subcomponents/FacilityTypeView';

const messages = defineMessages({
  title: {
    id: 'facility.type.title',
    defaultMessage: 'Facility Types'
  },
  count: {
    id: 'facility.type.count.label',
    defaultMessage: 'Facility type(s)'
  },
  searchPlaceHolder: {
    id: 'facility.type.search.placeholder',
    defaultMessage: 'Search facility types'
  },
  name: {
    id: 'facility.type.name',
    defaultMessage: 'Type Name'
  },
  role: {
    id: 'facility.type.role',
    defaultMessage: 'Role'
  },
  details: {
    id: 'facility.type.details',
    defaultMessage: 'Details'
  },
  add: {
    id: 'facility.type.add.button',
    defaultMessage: 'Create facility type'
  }
});

const ADD = permissions.facilityType.add;
const EDIT = permissions.facilityType.edit;
const DELETE = permissions.facilityType.del;

/**
 * Facility Type screen
 *
 * List of available facility types.
 *
 * @component
 * @example
 * return (
 *    <FacilityType />
 * );
 */
const FacilityType = () => {
  const db = useDatabase();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const subscription = getCollection(db, 'facilityType')
      .find()
      .where({ isActive: true })
      .sort({ name: 1 })
      .$
      .subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          name: entry.name,
          details: entry.details,
          role: entry.role,
          lastmodifiedAt: entry.lastmodifiedAt,
          lastSynchedAt: entry.lastSynchedAt
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db]);

  return (
    <MarginDiv data-testid='facility-type'>
      <DataEntity
        crumbs={['facilitiesGroup', 'facilityType']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.name },
          { dataKey: 'role.name', label: messages.role },
          { dataKey: 'details', label: messages.details, flex: 2 }
        ]}
        addPerm={ADD}
        editPerm={EDIT}
        FormComponent={FormComponent}
        deletePerm={DELETE}
        onDelete={deleteFacilityType}
        ViewComponent={ViewComponent}
        messages={messages}
      />

    </MarginDiv>
  );
};

export default FacilityType;
