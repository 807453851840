import { FormattedMessage } from 'react-intl';
import { Col, FlexboxGrid } from 'rsuite';

import documentIcon from '../../assets/svg-images/document.svg';
import { StyledDesc } from './styled';

/**
 * Empty Table component
 *
 * Empty table view to display component when there is no data in data table.
 *
 * @component
 * @example
 * return (
 *    <EmptyTable />
 * )
 *
 */
const EmptyTable = () => {
  return (
    <FlexboxGrid style={{ height: '68vh' }} justify='center' align='middle'>
      <Col align='center'>
        <img src={documentIcon} alt='no-records' />
        <StyledDesc>
          <FormattedMessage id='show.empty.page' defaultMessage='No Records Found' />
        </StyledDesc>
      </Col>
    </FlexboxGrid>
  );
};

export default EmptyTable;
