import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import {
  bloodTypeMsg,
  genderMsg,
  severityMsg,
  translations
} from '../../../translations/en/common';
import { parseDate } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'adverseEvent.title',
    defaultMessage: 'Adverse Event'
  },
  count: {
    id: 'adverseEvent.count.label',
    defaultMessage: 'Adverse Event Record(s)'
  },
  searchPlaceHolder: {
    id: 'adverseEvent.search.placeholder',
    defaultMessage: 'Search Adverse Event Records'
  },
  patientCode: {
    id: 'adverseEvent.patient.code',
    defaultMessage: 'Patient Code'
  },
  firstName: {
    id: 'adverseEvent.first.name',
    defaultMessage: 'First Name'
  },
  lastName: {
    id: 'adverseEvent.last.name',
    defaultMessage: 'Last Name'
  },
  facility: {
    id: 'adverseEvent.facility',
    defaultMessage: 'Facility'
  },
  severity: {
    id: 'adverseEvent.severity',
    defaultMessage: 'Severity'
  },
  dateOfTransfusion: {
    id: 'adverseEvent.dateOfTransfusion',
    defaultMessage: 'Date of transfusion'
  }
});

/**
 * Adverse Event screen
 *
 * Adverse event to display data table showing adverse events.
 *
 * @component
 * @example
 * return (
 *    <AdverseEvent />
 * );
 *
 */
const AdverseEvent = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();

  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    severity: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'adverseEvent')
      .find()
      .where({ isActive: true })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          patientCode: entry.transfusion.patient.patientCode
            ? entry.transfusion.patient.patientCode
            : formatMessage(translations.notApplicable),
          name: entry.transfusion.patient.firstName
            ? `${entry.transfusion.patient.firstName}`
            : formatMessage(translations.notApplicable),
          lastName: entry.transfusion.patient.lastName
            ? `${entry.transfusion.patient.lastName}`
            : formatMessage(translations.notApplicable),
          gender: entry.transfusion.patient.gender
            ? formatMessage(genderMsg[entry.transfusion.patient.gender])
            : formatMessage(translations.notApplicable),
          dateOfTransfusion: entry.transfusion.dueTime
            ? parseDate(`${entry.transfusion.dueTime}`)
            : formatMessage(translations.notApplicable),
          bloodType: entry.transfusion.patient.bloodType
            ? formatMessage(bloodTypeMsg[entry.transfusion.patient.bloodType])
            : formatMessage(translations.notApplicable),
          facility: entry.transfusion.hospital.name
            ? `${entry.transfusion.hospital.name}`
            : formatMessage(translations.notApplicable),
          details: entry.details
            ? entry.details
            : formatMessage(translations.notApplicable),
          severity: entry.severity
            ? formatMessage(severityMsg[entry.severity])
            : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'adverseEvent']}
        data={data}
        columns={[
          { dataKey: 'patientCode', label: messages.patientCode, flex: 1.5 },
          { dataKey: 'name', label: messages.firstName },
          { dataKey: 'lastName', label: messages.lastName },
          { dataKey: 'facility', label: messages.facility, flex: 2 },
          { dataKey: 'severity', label: messages.severity },
          { dataKey: 'dateOfTransfusion', label: messages.dateOfTransfusion }
        ]}
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};

export default AdverseEvent;
