import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import { H5, ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Adverse Event View screen
 *
 * Adverse event view to display each adverse event in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns adverse event view modal.
 *
 */
const AdverseEventView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='adverseEvent.details.title'
        defaultMessage='Adverse Event Report Information'
      />
    }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.patient.code'
                defaultMessage='Patient Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.patientCode}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.first.name'
                defaultMessage='First Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.last.name'
                defaultMessage='Last Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.lastName}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.gender'
                defaultMessage='Gender'
              />
            </ViewLabel>
            <ViewLabelValue>{item.gender}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.facility'
                defaultMessage='Facility'
              />
            </ViewLabel>
            <ViewLabelValue>{item.facility}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.date.transfusion'
                defaultMessage='Date of Transfusion'
              />
            </ViewLabel>
            <ViewLabelValue>{item.dateOfTransfusion}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.blood.type'
                defaultMessage='Blood Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.bloodType}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='adverseEvent.view.adverseEvent'
            defaultMessage='Adverse Event'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={24}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.severity'
                defaultMessage='Severity'
              />
            </ViewLabel>
            <ViewLabelValue>{item.severity}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={24}>
            <ViewLabel>
              <FormattedMessage
                id='adverseEvent.view.label.details'
                defaultMessage='Details'
              />
            </ViewLabel>
            <ViewLabelValue>{item.details}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='appointment.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default AdverseEventView;
