import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: ${(props) => props.theme.font.sizes.xxl};
  font-weight: ${(props) => props.theme.font.weights.bold};
  line-height: ${(props) => props.theme.font.sizes.xxl};
  letter-spacing: 0.7;
`;

export const H2 = styled.h2`
  font-size: ${(props) => props.theme.font.sizes.xl};
  font-weight: ${(props) => props.theme.font.weights.bold};
  line-height: ${(props) => props.theme.font.sizes.xl};
  letter-spacing: 0.5;
`;

export const H3 = styled.h3`
  font-size: ${(props) => props.theme.font.sizes.m};
  font-weight: ${(props) => props.theme.font.weights.bold};
  line-height: ${(props) => props.theme.font.sizes.l};
  letter-spacing: 0.4;
  color: ${(props) => props.theme.colors.font_dark} !important;
`;

export const H4 = styled.h4`
  font-size: ${(props) => props.theme.font.sizes.xm};
  font-weight: ${(props) => props.theme.font.weights.regular};
  line-height: ${(props) => props.theme.font.sizes.m};
  letter-spacing: 0.4;
`;

export const H5 = styled.h5`
  font-size: ${(props) => props.theme.font.sizes.standard};
  font-weight: ${(props) => props.theme.font.weights.bold};
  line-height: ${(props) => props.theme.font.sizes.m};
  color: ${(props) => props.theme.colors.font_dark};
`;

export const Label = styled.label`
  font-size: ${(props) => props.theme.font.sizes.s};
  line-height: ${(props) => props.theme.font.sizes.xxl};
  color: ${(props) => props.theme.colors.font_dark};
`;

export const ViewLabel = styled.label`
  display: block;
  font-size: ${(props) => props.theme.font.sizes.s};
  line-height: ${(props) => props.theme.font.sizes.xm};
  color: ${(props) => props.theme.colors.font};
`;

export const ViewLabelValue = styled(ViewLabel)`
  color: ${(props) => props.theme.colors.font_dark};
  margin-top: 10px;
`;

export const Hr = styled.hr`
  margin: 0;
  border-top: 1px solid ${(props) => props.theme.colors.grey_divider};
`;
