import { syncAppointment } from './blood-donors/appointment';
import { syncDonation } from './blood-donors/donation';
import { syncDonor } from './blood-donors/donor';
import { syncRepresentative } from './blood-donors/representative';
import { syncConsumable } from './consumables/consumable';
import { syncEquipment } from './equipments/equipment';
import { syncFacility } from './facilities/facility';
import { syncFacilityCategory } from './facilities/facility-category';
import { syncFacilityType } from './facilities/facility-type';
import { syncBloodProduct } from './laboratory/blood-product';
import { syncBloodProductType } from './laboratory/blood-product-type';
import { syncProcessing } from './laboratory/processing';
import { syncResult } from './laboratory/result';
import { syncRetentionSample } from './laboratory/retention-sample';
import { syncTest } from './laboratory/test';
import { syncTestType } from './laboratory/test-type';
import { syncWastage } from './laboratory/wastage';
import { syncCollectionTask } from './logistics/collection-task';
import { syncCollectionTaskConfirmation } from './logistics/collection-task-confirmation';
import { syncCrisisEvent } from './logistics/crisis-event';
import { syncDelivery } from './logistics/delivery';
import { syncDeliveryConfirmation } from './logistics/delivery-confirmation';
import { syncRequest } from './logistics/request';
import { syncRestrictedBloodUsage } from './logistics/restricted-blood-usage';
import { syncVehicle } from './logistics/vehicle';
import { syncAdverseEvent } from './patients/adverse-event';
import { syncCrossMatching } from './patients/cross-matching';
import { syncPatient } from './patients/patient';
import { syncTransfusion } from './patients/transfusion';
import { syncEthnicGroup } from './persons/ethnic-group';
import { syncLanguage } from './persons/language';
import { syncQuestionnaire } from './questionnaires/questionnaire';
import { syncQuestionnaireAnswer } from './questionnaires/questionnaire-answer';
import { syncRole } from './ums/role';
import { syncUser } from './ums/user';

/**
 * Starts synchronization with the declared collections
 *
 * @returns list of RxDB replications
 */
export const startSynchronization = (...args) => {
  const replications = [
    // users (drivers and health workers)
    ...syncUser(...args),
    ...syncRole(...args),

    // persons
    ...syncEthnicGroup(...args),
    ...syncLanguage(...args),

    // facilities
    ...syncFacility(...args),
    ...syncFacilityCategory(...args),
    ...syncFacilityType(...args),

    // logistic items
    ...syncConsumable(...args),
    ...syncEquipment(...args),
    ...syncVehicle(...args),

    // blood donors
    ...syncAppointment(...args),
    ...syncDonation(...args),
    ...syncDonor(...args),
    ...syncRepresentative(...args),

    // questionnaires
    ...syncQuestionnaire(...args),
    ...syncQuestionnaireAnswer(...args),

    // laboratory
    ...syncBloodProduct(...args),
    ...syncBloodProductType(...args),
    ...syncProcessing(...args),
    ...syncResult(...args),
    ...syncRetentionSample(...args),
    ...syncTest(...args),
    ...syncTestType(...args),
    ...syncWastage(...args),

    // logistic activities
    ...syncCollectionTask(...args),
    ...syncCollectionTaskConfirmation(...args),
    ...syncDelivery(...args),
    ...syncDeliveryConfirmation(...args),
    ...syncRequest(...args),

    ...syncCrisisEvent(...args),
    ...syncRestrictedBloodUsage(...args),

    // patients
    ...syncAdverseEvent(...args),
    ...syncCrossMatching(...args),
    ...syncPatient(...args),
    ...syncTransfusion(...args)
  ];

  replications.forEach((replication) => {
    replication.error$.subscribe((err) => {
      console.debug('Error:', err);

      if (Array.isArray(err.innerErrors)) {
        err.innerErrors.forEach((ie) => {
          console.error('Inner error:', ie);
        });
      }
    });
  });

  return replications;
};

/**
 * Cancel synchronization for all the indicated replication promises.
 *
 * @param {array} replications  list of replication states
 */
export const stopSynchronization = (replications) => {
  replications.forEach((replication) => {
    try {
      replication.cancel();
    } catch (e) {
      // ignore
    }
  });
};
