import { replicateGraphQL } from 'rxdb/plugins/replication-graphql';

import { permissions } from '../../utils/auth';
import { REPLICATION_INTERVAL, REPLICATION_SIZE } from '../../utils/constants';
import { GRAPHQL_ENDPOINT } from '../../utils/environment';
import { getCollection } from '../collections';

/**
 * Audit fields used in all the schemas
 */
export const auditFields = `
  isActive
  createdAt
  createdBy { username name }
  lastmodifiedAt
  lastmodifiedBy { username name }
`;

export const buildReplication = (
  db,
  auth,
  module,
  pullOptions = null,
  pushOptions = null
) => {
  let pull;
  if (pullOptions) {
    const { pullBuilder, pullQuery } = pullOptions;

    const pullPerms = [permissions[module].view];

    if (auth.hasPermissions(pullPerms)) {
      pull = {
        batchSize: REPLICATION_SIZE,
        dataPath: `data.${pullQuery}.results`,
        modifier: (doc) => ({ ...doc, lastSynchedAt: new Date().toISOString() }),
        queryBuilder: pullBuilder,
        // Since RxDB 13
        responseModifier: async function (
          // the exact response that was returned from the server
          plainResponse,
          // either 'handler' if plainResponse came from the pull.handler,
          // or 'stream' if it came from the pull.stream
          origin,
          // if origin==='handler', the requestCheckpoint contains
          // the checkpoint that was send to the backend
          requestCheckpoint
        ) {
          return {
            documents: plainResponse,
            checkpoint: plainResponse.length === 0
              ? requestCheckpoint
              : {
                  lastmodifiedAt: plainResponse[plainResponse.length - 1].lastmodifiedAt
                }
          };
        }
      };
    }
  }

  let push;
  if (pushOptions) {
    const { pushBuilder } = pushOptions;

    // Since RxDB 13 the push queryBuilder method receives a list of documents
    // then we need to parse it to the previous state
    const queryBuilder = (docs) => {
      return pushBuilder(docs[0].newDocumentState);
    };

    const pushPerms = [
      permissions[module].add,
      permissions[module].edit,
      permissions[module].del
    ];

    if (auth.hasAnyPermission(pushPerms)) {
      push = {
        batchSize: 1,
        queryBuilder
      };
    }
  }

  if (!pull && !push) return [];

  const collection = getCollection(db, module);

  return [
    replicateGraphQL({
      collection,
      url: { http: GRAPHQL_ENDPOINT },
      headers: { Authorization: `JWT ${auth.token}` },
      pull,
      push,
      live: true,
      liveInterval: REPLICATION_INTERVAL
    })
  ];
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} field   Field name
 * @returns string
 */
export const getMutationBooleanValue = (doc, field) => {
  const value = !!doc[field];
  return `${field}: ${value.toString()}`;
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} field   Field name
 * @returns string
 */
export const getMutationNumberValue = (doc, field) => {
  const value = doc[field] || 0;
  return `${field}: ${value.toString()}`;
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} field   Field name
 * @returns string
 */
export const getMutationDecimalValue = (doc, field) => {
  const value = doc[field] || 0;
  return `${field}: "${value.toString()}"`;
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} field   Field name
 * @returns string
 */
export const getMutationStringValue = (doc, field) => {
  const value = doc[field];
  if (!value) return '';
  return `${field}: "${value}"`;
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} field   Field name
 * @returns string
 */
export const getMutationStringsValue = (doc, field, destField = undefined) => {
  if (!destField) destField = field;
  if (!doc[field]) return '';
  const value = doc[field].map((item) => `"${item}"`).join(',');
  if (!value) return [];
  return `${destField}: [${value}]`;
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} field   Field name
 * @param {String} attr    Nested field name
 * @returns string
 */
export const getMutationNestedValue = (doc, field, attr = 'id') => {
  const value = doc[field];
  if (!value) return '';
  return `${field}: "${value[attr]}"`;
};

/**
 * Returns mutation input entry for indicated field based on document value
 *
 * @param {Object} doc     Document
 * @param {String} name    Mutation field name
 * @param {String} field   Field name
 * @param {String} attr    Nested field name
 * @returns string
 */
export const getMutationArrayNestedValue = (doc, name, field, attr = 'id') => {
  if (!doc[field]) return '';
  const value = doc[field].map((item) => `"${item[attr]}"`).join(',');
  if (!value) return '';
  return `${name}: [${value}]`;
};

/**
 * Returns mutation isActive input entry based on document value
 *
 * @param {Object} doc     Document
 * @returns string
 */
export const getMutationIsActive = (doc) => {
  return `isActive: ${doc.isActive === undefined ? true : doc.isActive}`;
};
