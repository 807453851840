import * as docx from 'docx';
import { saveAs } from 'file-saver';
import { jsPDF as JsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import pick from 'lodash/pick';
import moment from 'moment';
import React from 'react';
import { CSVLink } from 'react-csv';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Stack } from 'rsuite';
import FlexboxGrid from 'rsuite/FlexboxGrid';

import downloadIcon from '../../assets/svg-images/download.svg';
import dropdownIcon from '../../assets/svg-images/dropdown-chevron.svg';
import filterIcon from '../../assets/svg-images/filter.svg';
import { FilterButton, PrimaryButton } from '../../containers/styled/buttons';
import { SearchInput } from '../../containers/styled/inputs';
import { FaIcon, MarginLeft, MarginSpan } from '../../containers/styled/layout';
import { H3, Hr } from '../../containers/styled/typography';
import { useAuth } from '../../context/auth';
import BreadCrumb from '../routing/BreadCrumb';
import DataTable from './DataTable';
import { DropdownButton, DropdownList } from './styled';

const renderDropdown = (props, ref) => {
  return (
    <DropdownButton {...props} ref={ref}>
      <img src={downloadIcon} alt='download' />
      <span>Download Report</span>
      <img src={dropdownIcon} alt='arrow' />
    </DropdownButton>
  );
};

/**
 * Data Entity component
 *
 * Data entity displays Data table component along with search, filter,
 * add / edit new data and allows to download table data as PDF, CSV, and Docx.
 *
 * @component
 * @param {array} data           an array of objects representing the data to be displayed
 * @param {array} columns        an array of objects representing the columns to be displayed in the data table
 * @param {array} crumbs         an array of objects representing the breadcrumbs to be displayed at the top of the page
 * @param {string} addPerm       a string representing the permission required to add new data
 * @param {string} deletePerm    a string representing the permission required to delete data
 * @param onDelete               a function to be called when the user deletes a row from the data table
 * @param FormComponent          a react component to be displayed when the user wants to add or edit data
 * @param FilterComponent        a react component to be displayed when the user wants to filter the data table
 * @param {string} editPerm      a string representing the permission required to edit data
 * @param ViewComponent          a react component to be displayed when the user clicks on a row in the data table
 * @param {object} messages      an object representing the messages to be displayed in the component
 * @param {boolean} downloadable a boolean indicating whether the data can be exported to csv, pdf, and docx formats
 * @param {boolean} isStatus     a boolean indicating whether the data table has a column for status
 * @example
 * return (
 *   <DataEntity
 *     data={data}
 *     columns={columns}
 *     crumbs={['routeGroup', 'routeChild']}
 *     addPerm={ADD}
 *     deletePerm={DELETE}
 *     editPerm={EDIT}
 *     FormComponent={Component}
 *     FilterComponent={Component}
 *     ViewComponent={Component}
 *   />
 * )
 *
 */
const DataEntity = ({
  data,
  columns,
  crumbs,
  addPerm,
  deletePerm,
  onDelete,
  FormComponent,
  FilterComponent,
  editPerm,
  ViewComponent,
  messages,
  downloadable = false,
  isStatus = false
}) => {
  const { formatMessage } = useIntl();
  const { auth } = useAuth();
  const hasAddPerm = addPerm ? auth.hasPermission(addPerm) : false;

  const [filteredData, setFilteredData] = React.useState([]);

  const [searchText, setSearchText] = React.useState('');
  const [searchedData, setSearchedData] = React.useState([]);

  const [showAddForm, setShowAddForm] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(false);

  const [reportingColumns, setReportingColumns] = React.useState([]);
  const [reportingData, setReportingData] = React.useState([]);
  const [docLength, setDocLength] = React.useState(0);

  const generatePdf = () => {
    const doc = new JsPDF();

    autoTable(doc, {
      head: [reportingColumns.map((col) => col.label)],
      body: reportingData.map((item) => Object.values(item))
    });
    doc.save(`${formatMessage(messages.title)}.pdf`);
  };

  const generateDocx = () => {
    const doc = new docx.Document({
      sections: [{
        properties: {
          page: {
            pageNumbers: { start: 1, formatType: docx.NumberFormat.DECIMAL }
          }
        },
        headers: {
          default: new docx.Header({
            children: [new docx.Paragraph(formatMessage(messages.title))]
          })
        },
        footers: {
          default: new docx.Footer({
            children: [
              new docx.Paragraph({
                alignment: docx.AlignmentType.CENTER,
                children: [
                  new docx.TextRun(moment().format('lll')),
                  new docx.TextRun('\t\t'),
                  new docx.TextRun({
                    children: [
                      docx.PageNumber.CURRENT,
                      ' / ',
                      docx.PageNumber.TOTAL_PAGES
                    ]
                  })
                ]
              })]
          })
        },

        children: [
          // document title
          new docx.Paragraph({
            text: formatMessage(messages.title),
            heading: docx.HeadingLevel.HEADING_1,
            alignment: docx.AlignmentType.CENTER
          }),

          // table
          new docx.Table({
            width: { size: 100, type: docx.WidthType.PERCENTAGE },
            rows: [
              // headers
              new docx.TableRow({
                tableHeader: true,
                cantSplit: true,
                children: reportingColumns.map((column) => new docx.TableCell({
                  children: [new docx.Paragraph({
                    alignment: docx.AlignmentType.CENTER,
                    children: [
                      new docx.TextRun({
                        text: column.label,
                        bold: true,
                        allCaps: true
                      })
                    ]
                  })]
                }))
              }),

              // rows
              ...reportingData.map((row) => new docx.TableRow({
                cantSplit: true,
                children: reportingColumns.map((column) => new docx.TableCell({
                  children: [new docx.Paragraph(row[column.key])]
                }))
              }))
            ]
          })
        ]
      }]
    });

    docx.Packer.toBlob(doc).then(blob => {
      saveAs(blob, `${formatMessage(messages.title)}.docx`);
    });
  };

  // filter list every time that the search text changes
  React.useEffect(() => {
    const keys = columns.map((col) => col.dataKey);
    setSearchedData(
      filteredData.filter(
        (item) =>
          keys.some((key) => item[key].toString().toLowerCase().includes(searchText.toLowerCase()))
      )
    );

    setDocLength(
      searchedData.length
    );
  }, [filteredData, searchText, columns, searchedData.length]);

  // generate reporting data
  // QUESTION, use filtered data or data???
  React.useEffect(() => {
    setReportingColumns(columns.map((column) => ({
      key: column.dataKey,
      label: formatMessage(column.label)
    })));

    const cols = columns.map((column) => column.dataKey);
    setReportingData(data.map((row) => pick(row, cols)));
    setDocLength(data.length);
  }, [data, columns, formatMessage]);

  React.useEffect(() => {
    setFilteredData(data);
  }, [data]);

  React.useEffect(() => {
    setDocLength(filteredData.length);
  }, [filteredData]);

  return (
    <>
      <BreadCrumb crumbs={crumbs} />

      <MarginLeft>
        <FlexboxGrid align='bottom' style={{ marginBottom: '1.8rem' }}>
          <FlexboxGrid.Item>
            <H3>{formatMessage(messages.title)}</H3>
          </FlexboxGrid.Item>

          <FlexboxGrid.Item style={{ fontSize: 14, marginLeft: 30 }}>
            <MarginSpan>{docLength}</MarginSpan>
            {formatMessage(messages.count)}
          </FlexboxGrid.Item>
        </FlexboxGrid>

        <FlexboxGrid justify='space-between' align='bottom'>
          <Stack spacing={8}>
            <FlexboxGrid.Item>
              <SearchInput
                value={searchText}
                placeholder={formatMessage(messages.searchPlaceHolder)}
                onChange={setSearchText}
              />
            </FlexboxGrid.Item>

            {!!FilterComponent && (
              <FlexboxGrid.Item>
                <FilterButton onClick={() => setShowFilter(true)}>
                  <img
                    src={filterIcon}
                    alt='filter'
                    style={{ marginRight: 8 }}
                  />
                  <FormattedMessage
                    id='data.entity.filter-by'
                    defaultMessage='Filter by'
                  />
                </FilterButton>

                {showFilter && (
                  <FilterComponent
                    data={data}
                    setFilteredData={setFilteredData}
                    handleClose={() => setShowFilter(false)}
                  />
                )}
              </FlexboxGrid.Item>
            )}
          </Stack>

          <Stack>
            {hasAddPerm && (
              <FlexboxGrid.Item>
                <PrimaryButton style={{ padding: '0.75rem 1.25rem' }} onClick={() => setShowAddForm(true)}>
                  <FaIcon icon='plus' />
                  {formatMessage(messages.add)}
                </PrimaryButton>

                {showAddForm && (
                  <FormComponent handleClose={() => setShowAddForm(false)} />
                )}
              </FlexboxGrid.Item>
            )}

            {downloadable && (
              <FlexboxGrid.Item>
                <DropdownList renderToggle={renderDropdown}>
                  <CSVLink
                    filename={messages.title.defaultMessage}
                    data={reportingData}
                    headers={reportingColumns}
                  >
                    <Dropdown.Item>
                      <FormattedMessage
                        id='data.entity.download.csv'
                        defaultMessage='CSV'
                      />
                    </Dropdown.Item>
                  </CSVLink>
                  <Hr />
                  <Dropdown.Item onClick={() => generateDocx()}>
                    <FormattedMessage
                      id='data.entity.download.docx'
                      defaultMessage='Docx'
                    />
                  </Dropdown.Item>
                  <Hr />
                  <Dropdown.Item onClick={() => generatePdf()}>
                    <FormattedMessage
                      id='data.entity.download.pdf'
                      defaultMessage='PDF'
                    />
                  </Dropdown.Item>
                </DropdownList>
              </FlexboxGrid.Item>
            )}
          </Stack>
        </FlexboxGrid>

        <DataTable
          data={searchedData}
          columns={columns}
          ViewComponent={ViewComponent}
          isStatus={isStatus}
          editPerm={editPerm}
          FormComponent={FormComponent}
          deletePerm={deletePerm}
          onDelete={onDelete}
        />
      </MarginLeft>
    </>
  );
};

export default DataEntity;
