import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { severityMsg, statusMsg, translations } from '../../../translations/en/common';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'deliveries.title',
    defaultMessage: 'Deliveries'
  },
  count: {
    id: 'deliveries.count.label',
    defaultMessage: 'Delivery Record(s)'
  },
  searchPlaceHolder: {
    id: 'deliveries.search.placeholder',
    defaultMessage: 'Search Delivery Records'
  },
  bloodProductType: {
    id: 'deliveries.bloodProductType',
    defaultMessage: 'Blood Product Type'
  },
  quantity: {
    id: 'deliveries.quantity',
    defaultMessage: 'Quantity'
  },
  pickupPoint: {
    id: 'deliveries.pickup.point',
    defaultMessage: 'Pickup Point'
  },
  destination: {
    id: 'deliveries.destination',
    defaultMessage: 'Destination'
  },
  status: {
    id: 'deliveries.status',
    defaultMessage: 'Status'
  }
});

/**
 * Deliveries screen
 *
 * Deliveries to display data table showing deliveries.
 *
 * @component
 * @example
 * return (
 *    <Deliveries />
 * );
 *
 */
const Deliveries = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    destination: '',
    status: '',
    pickupPoint: '',
    priority: ''
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'delivery')
      .find()
      .where({ isActive: true })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          name:
            entry.bloodProducts.length > 0
              ? entry.bloodProducts.map(
                  (data) => data.bloodProductType.name
                )
              : formatMessage(translations.notApplicable),
          quantity:
            entry.bloodProducts.length > 0
              ? entry.bloodProducts.map((data) => data.volume)
              : formatMessage(translations.notApplicable),
          equipments:
            entry.equipments.length > 0
              ? entry.equipments.map((data) => data.name)
              : formatMessage(translations.notApplicable),
          pickupPoint: entry.pickupPoint
            ? entry.pickupPoint.name
            : formatMessage(translations.notApplicable),
          destination: entry.destination
            ? entry.destination.name
            : formatMessage(translations.notApplicable),
          status: entry.status
            ? formatMessage(statusMsg[entry.status])
            : formatMessage(translations.notApplicable),
          priority: entry.priority
            ? formatMessage(severityMsg[entry.priority])
            : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'deliveries']}
        data={data}
        columns={[
          { dataKey: 'name', label: messages.bloodProductType },
          { dataKey: 'quantity', label: messages.quantity },
          { dataKey: 'pickupPoint', label: messages.pickupPoint },
          { dataKey: 'destination', label: messages.destination }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};

export default Deliveries;
