import { REPLICATION_SIZE } from '../../../utils/constants';
import { auditFields, buildReplication } from '../utils';

/**
 * Pull builder for consumable data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listConsumables(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        name
        serialNumber
        manufacturerDate
        expiryDate

        facility { id name address }
        item {
          id
          name
          manufacturer
          model

          category { id name }
        }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Synchronize consumable data with GraphQL endpoint
 */
export const syncConsumable = (...args) => {
  return buildReplication(
    ...args,
    'consumable',
    { pullBuilder, pullQuery: 'listConsumables' }
  );
};
