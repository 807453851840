import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for appointment data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listAppointments(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode
        dateTime
        details

        bloodDonationCenter { id name address stockUnit { id name address } }
        donor { id donorCode fullName personName gender bloodType }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for appointment data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertAppointment(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dateTime')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'bloodDonationCenter')}
          ${getMutationNestedValue(doc, 'donor')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize appointment data with GraphQL endpoint
 */
export const syncAppointment = (...args) => {
  return buildReplication(
    ...args,
    'appointment',
    { pullBuilder, pullQuery: 'listAppointments' },
    { pushBuilder }
  );
};
