import { v4 as uuid4 } from 'uuid';

import { getCollection } from '../../rxdb/collections';

/**
 * List all facility categories (also disabled)
 *
 * @param {*} db    RxDB database
 * @returns Promise that returns the entries
 */
export const getFacilityCategories = (db) => {
  return getCollection(db, 'facilityCategory')
    .find()
    .exec()
    .then((documents) => {
      return documents.map((entry) => ({
        id: entry.id,
        name: entry.name,
        isActive: entry.isActive
      }));
    });
};

/**
 * Creates Facility category
 *
 * @param {*} db    RxDB database
 * @param {*} data  facility category data
 * @returns Promise indicating if action succeed
 */
export const createFacilityCategory = (db, data) => {
  const doc = {
    id: uuid4(),
    name: data.name,
    details: data.details,
    isActive: true,
    // Audit fields
    lastmodifiedAt: new Date().toISOString()
  };

  return getCollection(db, 'facilityCategory')
    .insert(doc)
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Updates Facility category
 *
 * @param {*}      db    RxDB database
 * @param {Object} data  facility category updated data (along with the id)
 * @returns Promise indicating if action succeed
 */
export const updateFacilityCategory = (db, data) => {
  const { id, ...updated } = data;
  const doc = getCollection(db, 'facilityCategory').findOne({ selector: { id } });

  return doc
    .update({
      $set: {
        ...updated,
        // Audit fields
        lastmodifiedAt: new Date().toISOString()
      }
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

/**
 * Disables Facility category
 *
 * @param {*}      db    RxDB database
 * @param {Object} data  facility category data
 * @returns Promise indicating if action succeed
 */
export const deleteFacilityCategory = (db, data) => {
  return updateFacilityCategory(db, {
    id: data.id,
    // workaround to skip name uniqueness constraint
    name: `${data.name} :: ${data.id} :: ${new Date().toISOString()}`,
    isActive: false
  });
};
