import { FormattedMessage } from 'react-intl';
import styled, { keyframes } from 'styled-components';

import AppLogo from '../assets/images/app-logo.svg';
import AppRelease from '../containers/AppRelease';

const logoSpin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

/**
 * @private
 */
const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  text-align: center;

  .home-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .home-logo {
      animation: ${logoSpin} infinite 20s linear;
    }
  }

  .home-header {
    align-items: center;
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.font};
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh;
  }

  .home-message {
    margin-top: 10px;
  }

  .app-version {
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.theme.font.sizes.s};
  }
`;

/**
 * Component that renders the Welcome message along with the current release.
 *
 * @component
 * @example
 * return (
 *    <Home />
 * );
 */
const Home = () => {
  return (
    <Wrapper>
      <header className='home-header'>
        <img src={AppLogo} height='90' alt='Biskit' />
        <div data-testid='home-welcome' className='home-message'>
          <FormattedMessage
            id='home.welcome'
            defaultMessage='Welcome to BISKIT'
          />
        </div>

        <AppRelease />
      </header>
    </Wrapper>
  );
};

export default Home;
