const getSuffix = (library, model) => `${model.toLowerCase()}.${library}.${model.toLowerCase()}`;

const getPermissions = (library, model) => ({
  view: `view_${getSuffix(library, model)}`,
  add: `add_${getSuffix(library, model)}`,
  edit: `change_${getSuffix(library, model)}`,
  del: `delete_${getSuffix(library, model)}`
});

/**
 * Dictionary with the app permissions
 */
export const permissions = {
  // users (drivers and health care workers)
  user: getPermissions('ums', 'user'),
  role: getPermissions('ums', 'role'),

  // persons
  ethnicGroup: getPermissions('persons', 'EthnicGroup'),
  language: getPermissions('persons', 'LanguageDialect'),

  // facilities
  facility: getPermissions('facilities', 'Facility'),
  facilityType: getPermissions('facilities', 'FacilityType'),
  facilityCategory: getPermissions('facilities', 'FacilityCategory'),

  // logistics items
  consumable: getPermissions('consumables', 'Consumable'),
  equipment: getPermissions('equipments', 'Equipment'),
  vehicle: getPermissions('logistics', 'Vehicle'),

  // blood donors
  appointment: getPermissions('blood_donors', 'Appointment'),
  donation: getPermissions('blood_donors', 'Donation'),
  donor: getPermissions('blood_donors', 'Donor'),
  representative: getPermissions('blood_donors', 'BloodDonorRepresentative'),

  // questionnaires
  questionnaire: getPermissions('questionnaires', 'Questionnaire'),
  questionnaireAnswer: getPermissions('questionnaires', 'QuestionnaireAnswer'),

  // laboratory
  result: getPermissions('laboratory', 'Result'),
  retentionSample: getPermissions('laboratory', 'RetentionSample'),
  test: getPermissions('laboratory', 'Test'),
  testType: getPermissions('laboratory', 'TestType'),

  bloodProduct: getPermissions('laboratory', 'BloodProduct'),
  bloodProductType: getPermissions('laboratory', 'BloodProductType'),
  processing: getPermissions('laboratory', 'Processing'),
  wastage: getPermissions('laboratory', 'Wastage'),

  // logistic activities
  collectionTask: getPermissions('logistics', 'CollectionTask'),
  collectionTaskConfirmation: getPermissions('logistics', 'CollectionConfirmation'),
  delivery: getPermissions('logistics', 'Delivery'),
  deliveryConfirmation: getPermissions('logistics', 'DeliveryConfirmation'),
  request: getPermissions('logistics', 'Request'),

  crisisEvent: getPermissions('logistics', 'CrisisEvent'),
  restrictedBloodUsage: getPermissions('logistics', 'RestrictedBloodUsage'),

  // patients
  adverseEvent: getPermissions('patients', 'AdverseEvent'),
  crossMatching: getPermissions('patients', 'CrossMatching'),
  patient: getPermissions('patients', 'Patient'),
  transfusion: getPermissions('patients', 'Transfusion')
};
