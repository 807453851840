import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { choices } from '../../../constants/choices';
import { FilterModal } from '../../../containers/styled/alerts';
import { TertiaryButton } from '../../../containers/styled/buttons';
import { TextFieldXs } from '../../../containers/styled/inputs';
import { SelectPickerField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import { listBloodProductTypes, listFacility } from '../../../controllers/reports/CommonController';
import { SelectDiv } from '../../generic/styled';

const messages = defineMessages({
  facilityPlaceholder: {
    id: 'report.filter.requests.facility.placeholder',
    defaultMessage: 'Select Facility'
  },
  statusPlaceholder: {
    id: 'report.filter.requests.status.placeholder',
    defaultMessage: 'Select Status'
  },
  bloodProductTypePlaceholder: {
    id: 'report.filter.requests.bloodProductType.placeholder',
    defaultMessage: 'Select Product Type'
  },
  quantityPlaceholder: {
    id: 'report.filter.requests.quantity.placeholder',
    defaultMessage: 'Enter Quantity'
  },
  priorityPlaceholder: {
    id: 'report.filter.requests.priority.placeholder',
    defaultMessage: 'Select Priority'
  }
});

/**
 * Request Filter screen
 *
 * Request filter to display modal to filter data table.
 *
 * @component
 * @param {array} data              an array of objects representing the data to be displayed
 * @param {boolean} handleClose     handles modal close event
 * @param setFilteredData           sets filtered data to the data table
 * @param initialQuery              default data to the data table
 * @param setInitialQuery           sets initialQuery data to table before modal closes
 * @returns request filter modal.
 *
 */
const RequestsFilter = ({ data, handleClose, setFilteredData, initialQuery, setInitialQuery }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [facilityData, setFacilityData] = React.useState([]);
  const [bloodProductTypeData, setBloodProductTypeData] = React.useState([]);
  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    bloodProductType: '',
    quantity: '',
    priority: ''
  });

  React.useEffect(() => {
    listFacility(db).then((data) => {
      const docs = data
        .filter((entry) => (entry.isActive && entry.requiresBlood)) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setFacilityData(docs);
    });
  }, [db]);

  React.useEffect(() => {
    listBloodProductTypes(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setBloodProductTypeData(docs);
    });
  }, [db]);

  const filterTable = () => {
    const filteredData = data.filter(
      (entry) =>
        (!query.facility || entry.requestingFacility === query.facility) &&
        (!query.status || entry.status === query.status) &&
        (!query.bloodProductType || entry.name === query.bloodProductType) &&
        (!query.quantity || entry.quantity.toString() === query.quantity) &&
        (!query.priority || entry.priority === query.priority)
    );
    setFilteredData(filteredData);
    setInitialQuery(query);
    handleClose();
  };

  React.useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <FilterModal onClose={handleClose} size='xs'>
      <Modal.Body style={{ marginLeft: 26, marginRight: 26 }}>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.requests.facility'
                  defaultMessage='Facility'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.facility}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.facilityPlaceholder)}
                  data={facilityData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, facility: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.requests.status'
                  defaultMessage='Status'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.status}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.statusPlaceholder)}
                  data={choices.requestStatus.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, status: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.requests.bloodProductType'
                  defaultMessage='Blood Product Type'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.bloodProductType}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.bloodProductTypePlaceholder)}
                  data={bloodProductTypeData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, bloodProductType: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.requests.quantity'
                  defaultMessage='Quantity'
                />
              </Label>
              <SelectDiv>
                <TextFieldXs
                  value={query.quantity}
                  style={{ padding: '8px 18px', fontSize: '12px' }}
                  type='number'
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, quantity: value }))}
                  placeholder={formatMessage(messages.quantityPlaceholder)}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.requests.priority'
                  defaultMessage='Priority'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.priority}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.priorityPlaceholder)}
                  data={choices.priority.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, priority: value }))}
                />
              </SelectDiv>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24}>
              <TertiaryButton onClick={() => filterTable()}>
                <FormattedMessage
                  id='report.filter.button'
                  defaultMessage='Filter'
                />
              </TertiaryButton>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </FilterModal>
  );
};
export default RequestsFilter;
