import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fontsource/open-sans';
import 'rsuite/dist/rsuite.min.css';
import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import AppWrapper from './containers/AppWrapper';
import reportWebVitals from './reportWebVitals';
import { expose } from './utils/environment';

expose();

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppWrapper>
      <App />
    </AppWrapper>
  </React.StrictMode>
);

reportWebVitals();
