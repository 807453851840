import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { statusMsg, translations } from '../../../translations/en/common';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'crossMatching.title',
    defaultMessage: 'Cross Matching'
  },
  count: {
    id: 'crossMatching.count.label',
    defaultMessage: 'Cross Matching Record(s)'
  },
  searchPlaceHolder: {
    id: 'crossMatching.search.placeholder',
    defaultMessage: 'Search Cross Matching Records'
  },
  bloodProduct: {
    id: 'crossMatching.bloodProduct',
    defaultMessage: 'Blood Product'
  },
  bloodType: {
    id: 'crossMatching.bloodType',
    defaultMessage: 'Blood Type'
  },
  patientName: {
    id: 'crossMatching.patient.name',
    defaultMessage: 'Patient Name'
  },
  isMatch: {
    id: 'crossMatching.isMatch',
    defaultMessage: 'Is Match'
  },
  status: {
    id: 'crossMatching.status',
    defaultMessage: 'Status'
  }
});

/**
 * Cross-matching screen
 *
 * Cross-matching to display data table showing cross-matchings.
 *
 * @component
 * @example
 * return (
 *    <CrossMatching />
 * );
 *
 */
const CrossMatching = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    match: ''
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'crossMatching')
      .find()
      .where({ isActive: true })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          isMatch: entry.isMatch
            ? formatMessage(translations.boolean.true)
            : formatMessage(translations.boolean.false),
          status: entry.status
            ? formatMessage(statusMsg[entry.status])
            : formatMessage(translations.notApplicable),
          bloodProduct: entry.bloodProduct.bloodProductType.name
            ? entry.bloodProduct.bloodProductType.name
            : formatMessage(translations.notApplicable),
          bloodType: entry.bloodProduct.bloodType
            ? entry.bloodProduct.bloodType
            : formatMessage(translations.notApplicable),
          name: entry.transfusion.patient.fullName
            ? entry.transfusion.patient.fullName
            : formatMessage(translations.notApplicable),
          healthCareWorkers:
            entry.healthCareWorkers.length > 0
              ? entry.healthCareWorkers.map((data) => data.name)
              : formatMessage(translations.notApplicable),
          equipments:
            entry.equipments.length > 0
              ? entry.equipments
              : formatMessage(translations.notApplicable),
          consumables:
            entry.consumables.length > 0
              ? entry.consumables
              : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'crossMatching']}
        data={data}
        columns={[
          { dataKey: 'bloodProduct', label: messages.bloodProduct, flex: 1.5 },
          { dataKey: 'bloodType', label: messages.bloodType },
          { dataKey: 'name', label: messages.patientName, flex: 2 },
          { dataKey: 'isMatch', label: messages.isMatch },
          { dataKey: 'status', label: messages.status }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
        isStatus
      />
    </MarginDiv>
  );
};

export default CrossMatching;
