import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import {
  bloodTypeMsg,
  genderMsg,
  translations
} from '../../../translations/en/common';
import { parseDate } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'donor.title',
    defaultMessage: 'Donors'
  },
  count: {
    id: 'donor.count.label',
    defaultMessage: 'Donor(s)'
  },
  searchPlaceHolder: {
    id: 'donor.search.placeholder',
    defaultMessage: 'Search Donors'
  },
  donorCode: {
    id: 'donor.donorCode',
    defaultMessage: 'Donor Code'
  },
  name: {
    id: 'donor.name',
    defaultMessage: 'Donor Name'
  },
  gender: {
    id: 'donor.gender',
    defaultMessage: 'Gender'
  },
  bloodType: {
    id: 'donor.blood.type',
    defaultMessage: 'Blood Type'
  },
  facility: {
    id: 'donor.facility',
    defaultMessage: 'Facility'
  }
});

/**
 * Donor screen
 *
 * Donor to display data table showing donors.
 *
 * @component
 * @example
 * return (
 *    <Donors />
 * );
 *
 */
const Donors = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    gender: '',
    facility: '',
    bloodType: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'donor')
      .find()
      .where({ isActive: true })
      .sort({ fullName: 1 })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          donorCode: entry.donorCode
            ? entry.donorCode
            : formatMessage(translations.notApplicable),
          name: entry.fullName
            ? entry.fullName
            : formatMessage(translations.notApplicable),
          firstName: entry.firstName
            ? entry.firstName
            : formatMessage(translations.notApplicable),
          lastName: entry.lastName
            ? entry.lastName
            : formatMessage(translations.notApplicable),
          gender: entry.gender
            ? formatMessage(genderMsg[entry.gender])
            : formatMessage(translations.notApplicable),
          dateOfBirth: entry.dateOfBirth
            ? parseDate(entry.dateOfBirth)
            : formatMessage(translations.notApplicable),
          facility: entry.bloodDonationCenter
            ? entry.bloodDonationCenter.name
            : formatMessage(translations.notApplicable),
          bloodType: entry.bloodType
            ? formatMessage(bloodTypeMsg[entry.bloodType])
            : formatMessage(translations.notApplicable),
          bloodQuality: entry.bloodQuality
            ? entry.bloodQuality
            : formatMessage(translations.notApplicable),
          consentAgreement: entry.consentAgreement
            ? formatMessage(translations.boolean.true)
            : formatMessage(translations.boolean.false),
          notificationConsent: entry.notificationConsent
            ? formatMessage(translations.boolean.true)
            : formatMessage(translations.boolean.false),
          ethnicGroup: entry.ethnicGroup
            ? entry.ethnicGroup.name
            : formatMessage(translations.notApplicable),
          languages:
            entry.languages.length > 0
              ? `${entry.languages.map((data) => data.name)}`
              : formatMessage(translations.notApplicable),
          documentTypeOne: entry.documentId1Type
            ? entry.documentId1Type
            : formatMessage(translations.notApplicable),
          documentNumberOne: entry.documentId1Number
            ? entry.documentId1Number
            : formatMessage(translations.notApplicable),
          documentIssuedByOne: entry.documentId1IssuedBy
            ? entry.documentId1IssuedBy
            : formatMessage(translations.notApplicable),
          documentExpireDateOne: entry.documentId1ExpireDate
            ? entry.documentId1ExpireDate
            : formatMessage(translations.notApplicable),
          documentTypeTwo: entry.documentId2Type
            ? entry.documentId2Type
            : formatMessage(translations.notApplicable),
          documentNumberTwo: entry.documentId2Number
            ? entry.documentId2Number
            : formatMessage(translations.notApplicable),
          documentIssuedByTwo: entry.documentId2IssuedBy
            ? entry.documentId2IssuedBy
            : formatMessage(translations.notApplicable),
          documentExpireDateTwo: entry.documentId2ExpireDate
            ? entry.documentId2ExpireDate
            : formatMessage(translations.notApplicable),
          email: entry.email1
            ? entry.email1
            : formatMessage(translations.notApplicable),
          mobile: entry.phone1Number
            ? entry.phone1Number
            : formatMessage(translations.notApplicable),
          address: entry.address1
            ? entry.address1
            : formatMessage(translations.notApplicable),
          city: entry.address1City
            ? entry.address1City
            : formatMessage(translations.notApplicable),
          postalCode: entry.address1PostalCode
            ? entry.address1PostalCode
            : formatMessage(translations.notApplicable),
          workEmail: entry.email2
            ? entry.email2
            : formatMessage(translations.notApplicable),
          workPhone: entry.phone2Number
            ? entry.phone2Number
            : formatMessage(translations.notApplicable),
          workAddress: entry.address2
            ? entry.address2
            : formatMessage(translations.notApplicable),
          workCity: entry.address2City
            ? entry.address2City
            : formatMessage(translations.notApplicable),
          workPostalCode: entry.address2PostalCode
            ? entry.address2PostalCode
            : formatMessage(translations.notApplicable),
          createdAt: entry.createdAt
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'donor']}
        data={data}
        columns={[
          { dataKey: 'donorCode', label: messages.donorCode, flex: 2 },
          { dataKey: 'name', label: messages.name, flex: 2 },
          { dataKey: 'gender', label: messages.gender },
          { dataKey: 'bloodType', label: messages.bloodType, flex: 1.5 },
          { dataKey: 'facility', label: messages.facility, flex: 3 }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};

export default Donors;
