import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationBooleanValue,
  getMutationDecimalValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationNumberValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for facility data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listFacilities(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        name
        details

        category { id name }
        type { id name }
        bloodBank { id name address stockUnit { id name address } }
        stockUnit { id name address }

        address
        city
        latitude
        longitude
        capacity

        acceptsDeliveries
        acceptsPatients
        collectsBlood
        processesBlood
        requiresBlood
        storesBlood
        suppliesBlood
        testsBlood

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for facility data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertFacility(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'name')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'category')}
          ${getMutationNestedValue(doc, 'type')}
          ${getMutationNestedValue(doc, 'bloodBank')}
          ${getMutationNestedValue(doc, 'stockUnit')}

          ${getMutationStringValue(doc, 'address')}
          ${getMutationStringValue(doc, 'city')}
          ${getMutationDecimalValue(doc, 'latitude')}
          ${getMutationDecimalValue(doc, 'longitude')}
          ${getMutationNumberValue(doc, 'capacity')}

          ${getMutationBooleanValue(doc, 'acceptsDeliveries')}
          ${getMutationBooleanValue(doc, 'acceptsPatients')}
          ${getMutationBooleanValue(doc, 'collectsBlood')}
          ${getMutationBooleanValue(doc, 'processesBlood')}
          ${getMutationBooleanValue(doc, 'requiresBlood')}
          ${getMutationBooleanValue(doc, 'storesBlood')}
          ${getMutationBooleanValue(doc, 'suppliesBlood')}
          ${getMutationBooleanValue(doc, 'testsBlood')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize facility data with GraphQL endpoint
 */
export const syncFacility = (...args) => {
  return buildReplication(
    ...args,
    'facility',
    { pullBuilder, pullQuery: 'listFacilities' },
    { pushBuilder }
  );
};
