import { getNewUUID } from '../../utils';

/** ID type */
const ID = { type: 'string', final: true, maxLength: 36 };

/** Boolean type */
const BOOLEAN = { type: 'boolean', default: false };

/** Date type */
const DATE = { type: ['string', 'null'], format: 'date', maxLength: 10 };
const DATE_INDEX = { type: 'string', format: 'date', maxLength: 10 };

/** Date-time type */
const DATE_TIME = { type: ['string', 'null'], format: 'datetime', maxLength: 50 };
const DATE_TIME_INDEX = { type: 'string', format: 'datetime', maxLength: 50 };

/** E-Mail type */
const EMAIL = { type: ['string', 'null'] };

/** Numeric type */
const NUMBER = { type: 'number', default: 0 };

/** Object type (does not indicate the properties) */
const OBJECT = { type: ['object', 'null'] };

/** String type */
const STRING = { type: ['string', 'null'] };
const STRING_INDEX = { type: 'string', maxLength: 255 };

/** Array of strings type */
const STRINGS = { type: 'array', items: STRING, uniqueItems: true };

/** Array of objects type */
const ARRAY = { type: 'array', items: OBJECT, uniqueItems: true };

/**
 * Field types
 */
export const types = {
  ARRAY,
  BOOLEAN,
  DATE_TIME,
  DATE_TIME_INDEX,
  DATE,
  DATE_INDEX,
  EMAIL,
  ID,
  NUMBER,
  OBJECT,
  STRING,
  STRING_INDEX,
  STRINGS
};

/**
 * Audit fields used in all the schemas
 */
export const auditFields = {
  // Controls when the instance should be shown to the user
  isActive: { ...BOOLEAN, default: true },
  // Audits instance manipulation
  createdAt: DATE_TIME,
  createdBy: OBJECT,
  lastmodifiedAt: DATE_TIME,
  lastmodifiedBy: OBJECT,
  // Controls synchronization with backend
  lastSynchedAt: DATE_TIME
};

/**
 * Migration step that keeps the document as it is
 * @param {Object} doc
 * @returns Same document
 */
export const emptyMigration = (doc) => doc;

/**
 * Creates new instance in collection
 *
 * @param {object} collection  RxDB collection
 * @param {object} data        New instance
 * @returns Promise
 */
const addInstance = (collection, data) => {
  const doc = {
    ...data,
    // generates "id" if not included in data
    id: data.id || getNewUUID(),

    // active
    isActive: true,
    // Audit fields
    lastmodifiedAt: new Date().toISOString()
  };

  return collection
    .insert(doc)
    .then(() => true)
    .catch((err) => {
      console.error('Error while inserting document');
      console.error(err);
      return false;
    });
};

/**
 * Updates instance in collection
 *
 * @param {object} collection  RxDB collection
 * @param {object} data        Instance
 * @returns Promise
 */
const editInstance = (collection, data) => {
  const { id, ...doc } = data;

  return collection
    .findOne({ selector: { id } })
    .update({
      $set: {
        ...doc,
        // Audit fields
        lastmodifiedAt: new Date().toISOString()
      }
    })
    .then(() => true)
    .catch((err) => {
      console.error('Error while updating document');
      console.error(err);
      return false;
    });
};

/**
 * Deletes instance in collection
 *
 * @param {object} collection  RxDB collection
 * @param {string} id          Instance ID
 * @returns Promise
 */
const delInstance = (collection, id) => {
  return collection.edit({ id, isActive: false });
};

/**
 * Collection operations
 */
export const operations = {
  add: addInstance,
  edit: editInstance,
  del: delInstance
};
