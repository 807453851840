import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Loader from 'rsuite/Loader';

import { goToHome, goToSignIn } from '../components/routing/routes';
import { loginGraphQL, refreshGraphQL } from '../controllers/auth-controller';
import { getCollection } from '../rxdb/collections';
import { startSynchronization, stopSynchronization } from '../rxdb/replications';
import { LOGOUT_INTERVAL, REFRESH_JWT_INTERVAL } from '../utils/constants';
import { useDatabase } from './database';

const messages = defineMessages({
  loading: {
    id: 'app.auth.loading',
    defaultMessage: 'Loading auth…'
  }
});

const JwtErrors = [
  'Error decoding signature',
  'Signature has expired',
  'User is disabled'
];

/**
 * Authorization context
 */
const AuthContext = React.createContext();

/**
 * Authorization hook
 *
 * @returns authorization objects: user, login, logout
 */
export const useAuth = () => React.useContext(AuthContext);

/**
 * Authorization Provider component
 *
 * Handles authentication and authorization for all nested children.
 * Provides:
 * - auth object
 * - login method
 * - logout method
 */
export const AuthProvider = ({ children }) => {
  const { formatMessage } = useIntl();

  const database = useDatabase();
  const collection = getCollection(database, 'auth');

  const [loaded, setLoaded] = React.useState(false);

  const [auth, setAuth] = React.useState({});
  const [replications, setReplications] = React.useState([]);

  /**
   * Log out:
   * - remove auth data from RxDB
   */
  const logout = React.useCallback(async () => {
    await collection.clearAuth();
    goToSignIn(); // redirect to SignIn route
  }, [collection]);

  /**
   * Log in:
   * - Uses GraphQL endpoint with provided credentials
   * - Saves in RxDB auth data: user, token and refresh token
   */
  const login = React.useCallback(async (username, password) => {
    const data = await loginGraphQL(username, password);
    await collection.setAuth(data);
    goToHome(); // redirect to Home route
  }, [collection]);

  /**
   * Refresh JWT
   * - Uses GraphQL endpoint with saved token and refresh token
   * - If succeed: updates auth entry
   * - If credentials issue: logs out
   */
  const refresh = React.useCallback(() => {
    if (!auth.refreshToken) return;

    refreshGraphQL(auth.refreshToken, auth.token)
      .then((data) => collection.setAuth(data))
      .catch((err) => {
        if (err.message === 'credentials') {
          logout();
        }
      });
  }, [collection, auth, logout]);

  const authContext = React.useMemo(() => ({ login, logout }), [login, logout]);

  /**
   * Start synchronization with a new JWT value
   */
  React.useEffect(() => {
    if (!auth.token) return;

    // start synchronization when there is login data
    const repl = startSynchronization(database, auth);
    setReplications(repl);

    return () => {
      stopSynchronization(repl);
      setReplications([]);
    };
  }, [database, auth]);

  /**
   * Stop synchronization if no token
   */
  React.useEffect(() => {
    // stop synchronization when there is no login data
    if (!auth.token && replications.length > 0) {
      stopSynchronization(replications);
      setReplications([]);
    }
  }, [auth.token, replications]);

  /**
   * Fetch auth saved data and start subscription.
   */
  React.useEffect(() => {
    if (loaded) return;

    const data = collection.getAuth();
    if (!data) return;

    data.$.subscribe((doc) => {
      // WORKAROUND!!!
      // if we pass the whole "doc" the component does not refresh
      // and the user is not redirected to home page
      const newAuth = {
        id: doc.id,
        // properties
        user: doc.user,
        token: doc.token,
        refreshToken: doc.refreshToken,
        // methods
        isSuperuser: doc.isSuperuser,
        hasPermission: doc.hasPermission,
        hasAnyPermission: doc.hasAnyPermission,
        hasPermissions: doc.hasPermissions
      };
      setAuth(newAuth);
    });

    data.exec().then(() => {
      setLoaded(true);
    });
  }, [collection, loaded]);

  /**
   * Check replication errors
   */
  React.useEffect(() => {
    replications.forEach((replication) => {
      replication.error$.subscribe(err => {
        if (Array.isArray(err.innerErrors)) {
          err.innerErrors.forEach((ie) => {
            if (JwtErrors.includes(ie.message)) {
              logout();
            }
          });
        }
      });
    });
  }, [replications, logout]);

  /**
   * Enables interval to refresh auth data
   */
  React.useEffect(() => {
    const interval = setInterval(() => {
      refresh();
    }, REFRESH_JWT_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [refresh]);

  /**
   * Enables timeout to force logout
   */
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      logout();
    }, LOGOUT_INTERVAL);

    return () => {
      clearTimeout(timeout);
    };
  }, [logout]);

  if (!loaded) {
    return (
      <span data-testid='app-auth-loading'>
        <Loader
          center
          vertical
          speed='slow'
          size='lg'
          content={formatMessage(messages.loading)}
        />
      </span>
    );
  }

  return (
    <AuthContext.Provider value={{ auth, ...authContext }}>
      {children}

      <span data-testid='app-auth-loaded' />
    </AuthContext.Provider>
  );
};
