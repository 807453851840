import React from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { MarginDiv } from '../../../containers/styled/layout';
import { useDatabase } from '../../../context/database';
import { getCollection } from '../../../rxdb/collections';
import { statusMsg, translations } from '../../../translations/en/common';
import { parseDate } from '../../../utils/dates';
import DataEntity from '../../generic/DataEntity';
import ViewComponent from './details';
import FilterComponent from './filter';

const messages = defineMessage({
  title: {
    id: 'bloodDonation.title',
    defaultMessage: 'Blood Donations'
  },
  count: {
    id: 'bloodDonation.count.label',
    defaultMessage: 'Blood Donation(s)'
  },
  searchPlaceHolder: {
    id: 'bloodDonation.search.placeholder',
    defaultMessage: 'Search Blood Donations'
  },
  donationId: {
    id: 'bloodDonation.id',
    defaultMessage: 'Donation ID'
  },
  donorCode: {
    id: 'bloodDonation.donor.code',
    defaultMessage: 'Donor Code'
  },
  donorName: {
    id: 'bloodDonation.donor.name',
    defaultMessage: 'Donor Name'
  },
  date: {
    id: 'bloodDonation.date',
    defaultMessage: 'Date'
  },
  length: {
    id: 'bloodDonation.length',
    defaultMessage: 'Length (cm)'
  },
  width: {
    id: 'bloodDonation.width',
    defaultMessage: 'Width (cm)'
  },
  volume: {
    id: 'bloodDonation.volume',
    defaultMessage: 'Volume (ml)'
  }
});

/**
 * Blood-donation screen
 *
 * Blood-donation to display data table showing blood-donation.
 *
 * @component
 * @example
 * return (
 *    <BloodDonations />
 * );
 *
 */
const BloodDonations = () => {
  const db = useDatabase();
  const { formatMessage } = useIntl();
  const [data, setData] = React.useState([]);

  const [query, setQuery] = React.useState({
    facility: '',
    status: '',
    datePeriod: []
  });

  React.useEffect(() => {
    const subscription = getCollection(db, 'donation')
      .find()
      .where({ isActive: true })
      .sort({ date: 'asc' })
      .$.subscribe((entries) => {
        const docs = entries.map((entry) => ({
          id: entry.id,
          donorCode: entry.donor
            ? entry.donor.donorCode
            : formatMessage(translations.notApplicable),
          date: entry.date
            ? parseDate(entry.date)
            : formatMessage(translations.notApplicable),
          name: entry.donor
            ? entry.donor.fullName
            : formatMessage(translations.notApplicable),
          length: entry.length
            ? entry.length
            : formatMessage(translations.notApplicable),
          width: entry.width
            ? entry.width
            : formatMessage(translations.notApplicable),
          volume: entry.volume
            ? entry.volume
            : formatMessage(translations.notApplicable),
          status: entry.status
            ? formatMessage(statusMsg[entry.status])
            : formatMessage(translations.notApplicable),
          facility: entry.bloodDonationCenter
            ? entry.bloodDonationCenter.name
            : formatMessage(translations.notApplicable),
          healthCareWorkers:
            entry.healthCareWorkers.length > 0
              ? entry.healthCareWorkers.map((data) => data.name)
              : formatMessage(translations.notApplicable),
          equipments:
            entry.equipments.length > 0
              ? entry.equipments
              : formatMessage(translations.notApplicable),
          consumables:
            entry.consumables.length > 0
              ? entry.consumables
              : formatMessage(translations.notApplicable)
        }));

        setData(docs);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [db, formatMessage]);

  return (
    <MarginDiv>
      <DataEntity
        crumbs={['reportsGroup', 'bloodDonation']}
        data={data}
        columns={[
          { dataKey: 'donorCode', label: messages.donorCode, flex: 1.5 },
          { dataKey: 'name', label: messages.donorName, flex: 2 },
          { dataKey: 'date', label: messages.date, flex: 1.5 },
          { dataKey: 'length', label: messages.length },
          { dataKey: 'width', label: messages.width },
          { dataKey: 'volume', label: messages.volume }
        ]}
        addPerm=''
        editPerm=''
        FormComponent=''
        FilterComponent={({ data, setFilteredData, handleClose }) => (
          <FilterComponent
            data={data}
            initialQuery={query}
            setInitialQuery={setQuery}
            setFilteredData={setFilteredData}
            handleClose={handleClose}
          />
        )}
        deletePerm=''
        onDelete=''
        ViewComponent={ViewComponent}
        messages={messages}
        downloadable
      />
    </MarginDiv>
  );
};

export default BloodDonations;
