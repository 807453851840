import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import { H5, ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Patient View screen
 *
 * Patient view to display each patient in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns patient view modal.
 *
 */
const PatientsView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='patients.details.title'
        defaultMessage='Patient Information'
      />
      }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.hospitalName'
                defaultMessage='Hospital Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.hospitalName}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.patientCode'
                defaultMessage='Patient Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.patientCode}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.firstName'
                defaultMessage='First Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.lastName'
                defaultMessage='Last Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.lastName}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.gender'
                defaultMessage='Gender'
              />
            </ViewLabel>
            <ViewLabelValue>{item.gender}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.dateOfBirth'
                defaultMessage='Date of Birth'
              />
            </ViewLabel>
            <ViewLabelValue>{item.dateOfBirth}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.bloodType'
                defaultMessage='Blood Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.bloodType}</ViewLabelValue>
          </Col>
          <Col xs={6}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.status'
                defaultMessage='Status'
              />
            </ViewLabel>
            <ViewLabelValue>{item.status}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={24}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.languages'
                defaultMessage='Language'
              />
            </ViewLabel>
            <ViewLabelValue>{item.languages}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='patients.view.details.documents'
            defaultMessage='Documents'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.document1Type'
                defaultMessage='Document Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId1Type}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.idNumber1'
                defaultMessage='ID Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId1Number}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.document1IssuedBy'
                defaultMessage='Issued By'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId1IssuedBy}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.expiryDate1'
                defaultMessage='Expiry Date'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId1ExpireDate}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.document2Type'
                defaultMessage='Document Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId2Type}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.idNumber2'
                defaultMessage='ID Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId2Number}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.document2IssuedBy'
                defaultMessage='Issued By'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId2IssuedBy}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.expiryDate2'
                defaultMessage='Expiry Date'
              />
            </ViewLabel>
            <ViewLabelValue>{item.documentId2ExpireDate}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='patients.view.details.contactInformation'
            defaultMessage='Contact Information'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.emailAddress'
                defaultMessage='Email Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.email1}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.phone1Number'
                defaultMessage='Mobile Phone Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.phone1Number}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.homeAddress'
                defaultMessage='Home Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address1}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.city'
                defaultMessage='City'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address1City}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.postalCode'
                defaultMessage='Postal Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address1PostalCode}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.workEmailAddress'
                defaultMessage='Email Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.email2}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.phone2Number'
                defaultMessage='Mobile Phone Number'
              />
            </ViewLabel>
            <ViewLabelValue>{item.phone2Number}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.workAddress'
                defaultMessage='Work Address'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address2}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.workCity'
                defaultMessage='City'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address2City}</ViewLabelValue>
          </Col>
          <Col xs={8}>
            <ViewLabel>
              <FormattedMessage
                id='patients.view.label.workPostalCode'
                defaultMessage='Postal Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.address2PostalCode}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='patients.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default PatientsView;
