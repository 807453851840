import omit from 'lodash/omit';

import { auditFields, operations, types } from '../common';

/**
 * Entity schema
 */
const schema = {
  version: 0,

  title: 'Delivery task',
  description: 'Blood products delivery task',

  primaryKey: 'id',
  type: 'object',
  properties: {
    id: types.ID,
    icode: types.STRING_INDEX,

    dueTime: types.DATE_TIME_INDEX,
    priority: types.STRING_INDEX, // category
    status: types.STRING_INDEX, // activity
    details: types.STRING,

    pickupPoint: types.OBJECT,
    destination: types.OBJECT,
    vehicle: types.OBJECT,
    driver: types.OBJECT,

    consumables: types.ARRAY,
    equipments: types.ARRAY,
    healthCareWorkers: types.ARRAY,

    bloodProducts: types.ARRAY,

    // Audit fields
    ...auditFields
  },

  required: ['id', 'dueTime', 'priority', 'status', 'pickupPoint', 'destination'],
  indexes: [
    ['dueTime'],
    ['priority'],
    ['status']
  ],
  keyCompression: true
};

/**
 * Migration strategies
 */
const migrationStrategies = {};

/**
 * Collection methods
 */
const statics = {
  /**
   * Creates new instance
   *
   * @param {object} data  New instance
   * @returns Promise
   */
  add: function (data) {
    // in the form the "bloodProductType" is required to select the blood products
    // but it is not included in the schema and we need to discard it
    const omitFields = ['bloodProductType'];
    return operations.add(this, omit(data, omitFields));
  },

  /**
   * Updates instance
   *
   * @param {object} data  Instance
   * @returns Promise
   */
  edit: function (data) {
    return operations.edit(this, data);
  },

  /**
   * Deletes instance
   *
   * @param {string} id  Instance ID
   * @returns Promise
   */
  del: function (id) {
    return operations.del(this, id);
  }
};

/**
 * Instance methods
 */
const methods = {};

/**
 * Collection definition
 */
const collection = { schema, migrationStrategies, statics, methods };

export default collection;
