import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import {
  H5,
  ViewLabel,
  ViewLabelValue
} from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Request View screen
 *
 * Request view to display each request in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns request view modal.
 *
 */
const RequestsView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='requests.details.title'
        defaultMessage='Request Details'
      />
    }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='requests.view.label.blood.product.type'
                defaultMessage='Blood Product Type'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='requests.view.label.requesting.facility'
                defaultMessage='Requesting Facility'
              />
            </ViewLabel>
            <ViewLabelValue>{item.requestingFacility}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='requests.view.label.quantity'
                defaultMessage='Quantity'
              />
            </ViewLabel>
            <ViewLabelValue>{item.quantity}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='requests.view.label.status'
                defaultMessage='Status'
              />
            </ViewLabel>
            <ViewLabelValue>{item.status}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='requests.view.label.bloodProduct'
                defaultMessage='Date & Time'
              />
            </ViewLabel>
            <ViewLabelValue>{item.dateTime}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='requests.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default RequestsView;
