import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationArrayNestedValue,
  getMutationIsActive,
  getMutationNestedValue,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for collection task data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listCollectionTasks(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode
        dueTime
        priority
        status
        details

        pickupPoint { id name address }
        destination { id name address }
        driver { username name }
        vehicle { id plateNumber modelMake }

        consumables { id name }
        equipments { id name }
        healthCareWorkers { username name }

        donations {
          id
          icode
          date
          length width volume
          donor { id donorCode fullName personName gender bloodType }
          bloodDonationCenter { id name address stockUnit { id name address } }
        }

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for collection task data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertCollectionTask(
        input: {
          ${getMutationStringValue(doc, 'id')}
          ${getMutationStringValue(doc, 'dueTime')}
          ${getMutationStringValue(doc, 'priority')}
          ${getMutationStringValue(doc, 'status')}
          ${getMutationStringValue(doc, 'details')}

          ${getMutationNestedValue(doc, 'pickupPoint')}
          ${getMutationNestedValue(doc, 'destination')}
          ${getMutationNestedValue(doc, 'driver', 'username')}
          ${getMutationNestedValue(doc, 'vehicle')}

          ${getMutationArrayNestedValue(doc, 'consumableIds', 'consumables')}
          ${getMutationArrayNestedValue(doc, 'equipmentIds', 'equipments')}
          ${getMutationArrayNestedValue(doc, 'healthCareWorkerNames', 'healthCareWorkers', 'username')}

          ${getMutationArrayNestedValue(doc, 'donationIds', 'donations')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize collection task data with GraphQL endpoint
 */
export const syncCollectionTask = (...args) => {
  return buildReplication(
    ...args,
    'collectionTask',
    { pullBuilder, pullQuery: 'listCollectionTasks' },
    { pushBuilder }
  );
};
