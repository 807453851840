import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { choices } from '../../../constants/choices';
import { FilterModal } from '../../../containers/styled/alerts';
import { TertiaryButton } from '../../../containers/styled/buttons';
import { SelectPickerField } from '../../../containers/styled/styled';
import { Label } from '../../../containers/styled/typography';
import { useDatabase } from '../../../context/database';
import { listDestinations, listPickupPoints } from '../../../controllers/facilities/FacilityController';
import { SelectDiv } from '../../generic/styled';

const messages = defineMessages({
  destinationPlaceholder: {
    id: 'report.filter.deliveries.destination.placeholder',
    defaultMessage: 'Select Destination'
  },
  statusPlaceholder: {
    id: 'report.filter.deliveries.status.placeholder',
    defaultMessage: 'Select Status'
  },
  pickupPointPlaceholder: {
    id: 'report.filter.deliveries.pickupPoint.placeholder',
    defaultMessage: 'Select Pickup Point'
  },
  priorityPlaceholder: {
    id: 'report.filter.deliveries.priority.placeholder',
    defaultMessage: 'Select Priority'
  }
});

/**
 * Deliveries Filter screen
 *
 * Deliveries filter to display modal to filter data table.
 *
 * @component
 * @param {array} data              an array of objects representing the data to be displayed
 * @param {boolean} handleClose     handles modal close event
 * @param setFilteredData           sets filtered data to the data table
 * @param initialQuery              default data to the data table
 * @param setInitialQuery           sets initialQuery data to table before modal closes
 * @returns deliveries filter modal.
 *
 */
const DeliveriesFilter = ({ data, handleClose, setFilteredData, initialQuery, setInitialQuery }) => {
  const { formatMessage } = useIntl();
  const db = useDatabase();
  const [destinationData, setDestinationData] = React.useState([]);
  const [pickupPointData, setPickupPointData] = React.useState([]);
  const [query, setQuery] = React.useState({
    destination: '',
    status: '',
    pickupPoint: '',
    priority: ''
  });

  React.useEffect(() => {
    listDestinations(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setDestinationData(docs);
    });
  }, [db]);

  React.useEffect(() => {
    listPickupPoints(db).then((data) => {
      const docs = data
        .filter((entry) => entry.isActive) // filter out inactive entries
        .map((entry) => ({
          value: entry.name,
          label: entry.name
        }));
      setPickupPointData(docs);
    });
  }, [db]);

  const filterTable = () => {
    const filteredData = data.filter(
      (entry) =>
        (!query.destination || entry.destination === query.destination) &&
        (!query.status || entry.status === query.status) &&
        (!query.pickupPoint || entry.pickupPoint === query.pickupPoint) &&
        (!query.priority || entry.priority === query.priority)
    );
    setFilteredData(filteredData);
    setInitialQuery(query);
    handleClose();
  };

  React.useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  return (
    <FilterModal onClose={handleClose} size='xs'>
      <Modal.Body style={{ marginLeft: 26, marginRight: 26 }}>
        <Grid fluid>
          <Row>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.deliveries.destination'
                  defaultMessage='Destination'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.destination}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.destinationPlaceholder)}
                  data={destinationData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, destination: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.deliveries.status'
                  defaultMessage='Status'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.status}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.statusPlaceholder)}
                  data={choices.deliveryStatus.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, status: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.deliveries.pickupPoint'
                  defaultMessage='Pickup Point'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.pickupPoint}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.pickupPointPlaceholder)}
                  data={pickupPointData}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, pickupPoint: value }))}
                />
              </SelectDiv>
            </Col>
            <Col xs={24}>
              <Label>
                <FormattedMessage
                  id='report.filter.deliveries.priority'
                  defaultMessage='Priority'
                />
              </Label>
              <SelectDiv>
                <SelectPickerField
                  value={query.priority}
                  searchable={false}
                  className='select-md'
                  placeholder={formatMessage(messages.priorityPlaceholder)}
                  data={choices.priority.map((data) => ({
                    label: data.name.defaultMessage,
                    value: data.name.defaultMessage
                  }))}
                  onChange={(value) =>
                    setQuery((state) => ({ ...state, priority: value }))}
                />
              </SelectDiv>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24}>
              <TertiaryButton onClick={() => filterTable()}>
                <FormattedMessage
                  id='report.filter.button'
                  defaultMessage='Filter'
                />
              </TertiaryButton>
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </FilterModal>
  );
};
export default DeliveriesFilter;
