import { FormattedMessage } from 'react-intl';
import { Col, Grid, Modal, Row } from 'rsuite';

import { OpenModal } from '../../../containers/styled/alerts';
import { ViewMarginDiv } from '../../../containers/styled/layout';
import { StyledModalDivider } from '../../../containers/styled/styled';
import { H5, ViewLabel, ViewLabelValue } from '../../../containers/styled/typography';
import AuditFieldsView from '../../generic/AuditFieldsView';

/**
 * Appointment View screen
 *
 * Appointment view to display each appointment in-detail.
 *
 * @component
 * @param {object} item              prop used to prepopulate the form fields with data when editing
 * @param {boolean} handleClose      handles modal close event
 * @returns appointment view modal.
 *
 */
const AppointmentView = ({ item, handleClose }) => (
  <OpenModal
    onClose={handleClose}
    size='md'
    title={
      <FormattedMessage
        id='appointment.details.title'
        defaultMessage='Appointment Information'
      />
    }
  >
    <Modal.Body style={{ marginBottom: 30 }}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='appointment.view.label.donor.code'
                defaultMessage='Donor Code'
              />
            </ViewLabel>
            <ViewLabelValue>{item.donorCode}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='appointment.view.label.donor.name'
                defaultMessage='Donor Name'
              />
            </ViewLabel>
            <ViewLabelValue>{item.name}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
        <H5>
          <FormattedMessage
            id='appointment.view.details'
            defaultMessage='Appointment Details'
          />
        </H5>
        <StyledModalDivider />
        <Row>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='appointment.view.label.dateTime'
                defaultMessage='Date & Time'
              />
            </ViewLabel>
            <ViewLabelValue>{item.dateTime}</ViewLabelValue>
          </Col>
          <Col xs={12}>
            <ViewLabel>
              <FormattedMessage
                id='appointment.view.label.facility'
                defaultMessage='Facility'
              />
            </ViewLabel>
            <ViewLabelValue>{item.facility}</ViewLabelValue>
          </Col>
        </Row>
        <ViewMarginDiv />
      </Grid>
      <H5>
        <FormattedMessage
          id='appointment.view.details.otherInformation'
          defaultMessage='Other Information'
        />
      </H5>
      <StyledModalDivider />
      <AuditFieldsView item={item} />
    </Modal.Body>
  </OpenModal>
);

export default AppointmentView;
