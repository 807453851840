import { REPLICATION_SIZE } from '../../../utils/constants';
import {
  auditFields,
  buildReplication,
  getMutationIsActive,
  getMutationStringValue
} from '../utils';

/**
 * Pull builder for restricted blood usage data
 *
 * @param {*} db RxDB database
 */
const pullBuilder = (doc) => {
  const where = doc ? `lastmodifiedAt_Gt: "${doc.lastmodifiedAt}"` : '';

  const query = `{
    listRestrictedBloodUsages(
      limit: ${REPLICATION_SIZE}
      offset: 0
      ordering: "lastmodifiedAt,ASC"
      ${where}
    ) {
      results {
        id
        icode

        startTime
        endTime

        details

        ${auditFields}
      }
    }
  }`;

  return { query, variables: {} };
};

/**
 * Push builder for restricted blood usage data
 *
 * @param {*} db RxDB database
 */
const pushBuilder = (doc) => {
  const query = `
    mutation {
      upsertRestrictedBloodUsage(
        input: {
          ${getMutationStringValue(doc, 'id')}

          ${getMutationStringValue(doc, 'startTime')}
          ${getMutationStringValue(doc, 'endTime')}

          ${getMutationStringValue(doc, 'details')}

          ${getMutationIsActive(doc)}
        }
      ) {
        id
      }
    }
  `;

  return { query, variables: {} };
};

/**
 * Synchronize restricted blood usage data with GraphQL endpoint
 */
export const syncRestrictedBloodUsage = (...args) => {
  return buildReplication(
    ...args,
    'restrictedBloodUsage',
    { pullBuilder, pullQuery: 'listRestrictedBloodUsages' },
    { pushBuilder }
  );
};
