/**
 * Validates name uniqueness
 *
 * @param value  Name to check
 * @param id     Current entry id
 * @param data   List of all existing entries
 * @returns a boolean
 * @throws Error if the value is already in use by another entry
 */
export const validateNameUniqueness = (value, id, data) => {
  if (!value || data.length === 0) return true;
  const otherEntries = id ? data.filter((entry) => entry.id !== id) : data;
  if (
    otherEntries.find(
      (entry) => entry.name.toLowerCase() === value.toLowerCase()
    )
  ) {
    throw Error('duplicated');
  }
  return true;
};

/**
 * Checks value length
 *
 * @param value       current value
 * @param maxLength   maximum allowed length
 * @returns a boolean
 * @throws Error if the value length is not valid
 */
export const validateLength = (value, maxLength = 40) => {
  if (!value || value.length <= 0) {
    throw Error('blank');
  }

  if (value.length > maxLength) {
    throw Error('max-length');
  }
  return true;
};
